select, button, a, input
  &:active, &:hover, &:focus
    box-shadow: none !important

input::-ms-reveal, input::-ms-clear
  display: none

@mixin custom-buttom-btn-default
  border-radius: 0px
  line-height: 1.5
  border-color: transparent
  width: 185px
  height: 34px
  outline: none

h5
  font-size: 16px
  font-weight: 700

.m-0
  margin: 0
.mt-10
  margin-top: 10px
.mt-20
  margin-top: 20px
.mt-30
  margin-top: 30px
.ml-5
  margin-left: 5px
.ml-10
  margin-left: 10px
.ml-20
  margin-left: 20px
.mr-0
  margin-right: 0 !important
.mr-5
  margin-right: 5px
.mr-10
  margin-right: 10px
.mr-20
  margin-right: 20px
.mr-30
  margin-right: 30px
.mb-0
  margin-bottom: 0
.mb-5
  margin-bottom: 5px
.mb-10
  margin-bottom: 10px !important
.mb-20
  margin-bottom: 20px
.mb-30
  margin-bottom: 30px
.p-0
  padding: 0
.pt-0
  padding-top: 0
.pt-5
  padding-top: 5px !important
.pl-10
  padding-left: 10px

.no-border
  border: none

.no-hover
  pointer-events: none
  
.textarea-duallistbox
  width: calc(50% - 5px)
  height: 80px
  resize: vertical
  border: 1px solid #000
  white-space: pre

.modalCompanyKey
  label
    display: inline-block
    max-width: 100%
    margin-bottom: 5px
    font-weight: 700
    font-size: 14px

.main-content-block
  hr
    margin: 20px 0px
    clear: both
    border-color: #ccc
  label
    font-weight: 100
    display: inline-block
    max-width: 100%
    margin-bottom: 5px
    font-size: 16px
    p
      margin-bottom: 1px

.main-content-background-border
  padding: 15px
  @media (min-width: 768px)
    border: 15px solid #F0F0F0
  &.no-border
    border: none
    padding-top: 15px

.edit-asset-block, .manage-users-edit, .add-asset-block
  a.custom-link
    text-decoration: none
    outline: none

.main-content
  padding: 28px 10px
  h5
    font-weight: 100
  p
    span
      font-size: 0.6875rem

  p, table
    color: #666666
    font-size: 0.8125rem
    font-size: 13px

  .p-12
    margin-top: 10px
    p
      font-size: 0.75rem

  table
    margin-top: 18px
    thead
      background-color: var(--theme-colors-primary)
      color: #000

    tbody>tr:nth-of-type(odd)
      background-color: #DEF3FA

    tr>th, tr>td
      padding-left: 20px

@media (min-width: 501px) and (max-width: 991px)
  .date-container-mobile
    margin-bottom: 10px

@media (min-width: 768px) and (max-width: 991px)
  .eventalert-container-mobile
    text-align: right

.no-events-message
  text-align: center
  font-size: 1.125rem

.has-error .checkbox, .has-error .checkbox-inline, .has-error .control-label, .has-error .help-block, .has-error .radio, .has-error .radio-inline, .has-error.checkbox label, .has-error.checkbox-inline label, .has-error.radio label, .has-error.radio-inline label
    color: #f00
.has-error input[type=text], .form-wrapper .has-error input[type=password], .has-error select, .has-error .form-select2, .has-error textarea, .has-error.upload-file-form, .has-error input.form-control
    border: 2px solid #f00
.has-error input.vehicle-search
    border-width: 0px 0px 2px 0px
    border-color: #f00 !important
.has-error .help-block
    color: #f00 !important
    margin-top: 0px
    margin-bottom: 0px
    margin-left: 5px
    font-weight: bold
.edit-group, .page-subheader, .password-container
  .help-block
    position: absolute
.password-container
  .help-block
    bottom: -20px
    margin-left: 0
.change-password
  form
    section
      margin-top: 0px
      .form-group
        .help-block
          display: block
          height: 15px
          bottom: 0

.form-select2
    .Select-control
        border-radius: 1px
        border: 2px solid #C1C1B9
        height: 34px
        .Select-placeholder
            line-height: 30px
        .Select-input
            height: 18px
            input
                padding: 4px 0 0 0
        .Select-arrow
            border-width: 6px 3px 1.5px
            border-color: #000 transparent transparent

.css-1p3m7a8-multiValue
  margin: 0px
  background-color: #f0f0f0 !important
  border: 1px solid var(--theme-colors-primary)
  color: var(--theme-colors-primary)
  div
    color: var(--theme-colors-primary) !important
  div:first-child
    order: 1
  div:nth-child(2)
    order: 0
    border-right: 1px solid rgba(0, 126, 255, 0.24)

.css-3w2yfm-ValueContainer, .css-1dyz3mf
  padding: 0 !important
  margin: 0 !important
  .css-1p3m7a8-multiValue
    margin-bottom: 0 !important

.css-1dyz3mf
  padding: 0

.error-table
  width: 93%
  display: table
  .react-bs-container-header
    display: none
  .react-bs-container-body
    margin-top: -19px
    tr
      td
        border-right: 8px solid #fff
    .table-bordered
      border: none

.manage-users-table, .manage-assets-table, .manage-groups-table, .error-table
  .react-bs-table
    margin-bottom: 0
    a
      display: inline-block
      img
        margin-right: 4px
    .actions-wrapper
      button
        i
          vertical-align: middle

    p
      margin: 0 0 0
      display: block
      img
        margin-right: 4px
    i
      font-size: 1.125rem
      margin-right: 4px
    a.unlock-flood-events
      margin-left: 10px

  .react-bs-table-pagination
    .pagination-btn-group
      font-size: 0.75rem
      color: #666666
      a
        text-decoration: underline
        margin-left: 5px
      a.active
        text-decoration: none
    .react-bootstrap-table-page-btns-ul.pagination
        float: right

//EDIT AND ADD COMPONENTS
.edit-asset-block
  h4
    font-size: 18px
    font-weight: 500
  .adas-calibration
    &.disabled
      .box-toggle
        cursor: not-allowed
        .react-toggle
          opacity: 0.5
          pointer-events: none
      input
        opacity: 0.5
        pointer-events: none
    .input-wrapper
      .settings-title
        width: 165px
  .settings-section
    .info-category
      margin-bottom: 30px
  .device-grid
    align-items: center
    &.align-bottom
      align-items: end
    &.param-grid
      grid-template-columns: auto 1fr
      .dv6
        background-image: url("../../../../../../public/images/dv6Image.png")
        background-size: 350px auto
        margin: 0px 0px 40px 40px
        min-height: 170px
        background-repeat: no-repeat
      .dv4
        background-image: url("https://roscomirrors.com/images/products/dv.jpg")
        background-size: 315px auto
        background-position: 40px -65px
        min-height: 200px
        background-repeat: no-repeat
      @media (max-width: 768px)
        grid-template-columns: 1fr
        .device-type
          background-position-x: center
    .control-label, .device-label
      font-size: 16px
      font-weight: 500
      &.toggle-left-title
        font-weight: 300
      a
        font-weight: 400
        margin-left: 15px
        text-decoration: none
    .flex-item
      display: flex
      align-items: center
      .control-label
        margin: 0px 8px 0px 0px
    .toggles-container
      margin-top: 1em
      justify-content: space-between
      &.justify-evenly
        justify-content: space-evenly
  .profile-wrapper
    .control-label
      margin-right: 5px
      margin-bottom: 0px
      font-size: 20px
      vertical-align: middle
    .button-link
      margin-right: 5px
      text-decoration: none
    .rosco-primary.inverted
      margin: 10px 0
  .button-wrapper
    display: flex
    align-items: center
    @media (max-width: 600px)
      margin-top: 15px
      flex-direction: column
      gap: 10px
      .rosco-primary.inverted
        margin: 0 !important
    .rosco-primary.inverted
      & + .rosco-primary.inverted
        margin-left: 1em
      @media (max-width: 991px)
        flex-grow: 1
  .profile-wrapper, .button-wrapper
    @media (min-width: 992px)
      margin-left: 15px
  .info-category
    margin: 0 0 15px
    .info-title
      margin: 0 0 8px
      font-weight: 400
  .device-param
    font-size: 1rem
    margin-top: 2px
    margin-bottom: 2px
  .vehicle-search
    padding-top: 4px
  .pair-external-devices
    margin-right: 1em
    button
      min-width: 185px
      width: auto !important
    @media (max-width: 600px)
      margin-right: 0 !important
    .dropdown-menu>a
      &:hover
        color: #fff
        background-color: var(--theme-colors-tertiary)
        text-decoration: none
    button
      display: inline-block
      padding: 6px 12px
      width: 185px
      margin-bottom: 0
      font-size: 14px
      font-weight: 400
      line-height: 1.42857143
      text-align: center
      white-space: nowrap
      vertical-align: middle
      color: var(--theme-colors-primary)
      border: 2px solid var(--theme-colors-primary)
      background-color: #fff
      &:focus, &:active, &:active:focus
        background-color: #ccc
        outline: none
      &:hover, &:active, &:focus, &:active:focus
        background-color: #e6e6e6
        color: var(--theme-colors-primary)
        border: 2px solid var(--theme-colors-primary)
  .icon-dropdown
    width: auto
    height: auto
    max-height: 46px
    margin-top: 17px
    .dropdown-toggle
      color: #333
      outline: none
      box-shadow: none
      background-color: transparent
      border: 2px solid var(--theme-colors-primary)
      border-radius: 0px
      display: flex
      justify-content: space-between
      align-items: center
      width: 100%
      &:hover, &:active, &:focus
        border-color: var(--theme-colors-tertiary)
        box-shadow: none
      img
        display: inline-block
        height: 30px
    .dropdown-menu
      height: 400px
      overflow: auto
      width: 100%
      .icon-menu
        height: 100%
        text-align: center
        .icon-wrapper
          padding: 5px
          &:hover
            background-color: var(--theme-colors-tertiary)
            cursor: pointer
            .dropdown-item
              background-color: transparent
          img
            margin: auto
            height: 30px
  .pair-external-devices
    margin-right: 1em
  .dropdown.btn-group
    .dropdown-menu>li>a
      &:hover
        color: #fff
        background-color: var(--theme-colors-tertiary)
  .dropdown.btn-group button, .open>.dropdown-toggle.btn-default
    color: var(--theme-colors-primary)
    border: 2px solid var(--theme-colors-primary)
  .camera-toggle
    &.inline
      display: flex
      .device-label
        margin-right: 15px
  .fleet-activity-status
    display: flex
    .status-row
      margin-left: 10px
      margin-bottom: 0
.add-asset-block
  margin-bottom: 10px
  .custom-button.rosco-secondary.inverted
    min-width: 185px
    &:focus, &:active
      box-shadow: none
  .label-wrapper
    margin-top: 10px
  .three-column-grid, .two-column-grid
    margin: 5px 0px 20px
    .control-label
      margin-bottom: 0px
    .rdt input
      border-color: var(--theme-colors-primary)
      width: 185px
    #user-role, #partner_name
      margin-top: 7px
      padding: 8px 4px 4px 5px
      @media (min-width: 767px) and (max-width: 991px)
        margin-top: -1px
    #user-groups
      height: auto
      padding: 0
.add-asset-block, .edit-content-block, .edit-company
  .two-column-grid .toggle-date-wrapper
    display: flex
    .toggle-label
      flex-basis: 50%
.edit-group
  .mobile-dual-list-wrapper, .dual-list-label
    display: flex
    gap: 3.5em
  .dual-list-label label
    flex-basis: 50%
  .mobile-dual-list-wrapper
    margin: 10px 0px 20px
  .dual-list-label, .react-dual-listbox, .mobile-dual-list-wrapper
    @media (min-width: 992px)
      margin-left: 15px

.edit-content-block
  padding-top: 7px
  color: #666666
  .custom-duallistbox
    .react-dual-listbox select.rdl-control
      min-height: auto

  .custom-duallistbox.selected-one
    .react-dual-listbox button.rdl-move-all
      display: none

  .form-group.add-asset-multiple
    padding: 0 0 0 0

@media (max-width: 767px)
  .custom-border-desktop
    display: none

.cursor
  cursor: pointer

.device-install-images
  display: flex
  overflow-x: auto
  overflow-y: hidden
  width: 100%
  img
    height: 160px
    margin-right: 5px
    cursor: pointer

.pulse
  -webkit-animation: pulse 3s ease-out
  -webkit-animation-iteration-count: infinite
  opacity: 0.5
  @-webkit-keyframes pulse
    0%
      opacity: 0.5
    50%
      opacity: 1.0
    100%
      opacity: 0.5

.modal-open .modal
  z-index: 100005

.modal-backdrop
  z-index: 100001
  &.upper
    z-index: 100005

table tr td .driver-photo
  height: 35px
  width: auto
  margin-right: 10px
  margin-bottom: 4px
  cursor: pointer
  &.zoom
    transform: scale(5)

.photos-list
  margin-top: 30px
  overflow: auto
  display: flex
  .driver-photo
    margin-right: 15px
    max-height: 300px
    width: auto

.driver-autocomplete
  .items-list
    .item
      padding: 0 5px
      cursor: pointer
      &.item-highlighted
          background-color: #00B0F2
          color: #ffffff

.form-group
  .control-label
    max-width: 100%
    margin-bottom: 5px
    font-weight: 700
    font-size: 14px
    line-height: 1.42857143

.icon-block
  padding-top: 16px
  padding-bottom: 6px
  img
    max-width: 122px
    max-height: 50px

.add-group-block .add-group-form.form-group
  width: 400px
  @media (max-width: 500px)
    width: 100%
.manage-groups, .manage-partners
  max-width: 1000px


.edit-user-container-mobile
  .table
    min-width: 500px
  @media (max-width: 767px)
    .input-sensor-block
      padding-right: 0
      padding-left: 0
  @media (max-width: 500px)
    .delete-user-btn
      width: 100%

.default-modal-footer
  @media (max-width: 500px)
    .button-wrapper
      width: 48%
      &.long-btn-text
        .btn-default
          padding-left: 0px
          padding-right: 0px
    .event-footer-wrapper,
    .report-footer-wrapper
      width: 48%
      &:first-of-type
        float: left
      &:last-of-type
        float: right
    .buttons-container-wrapper
      width: 100%

@media (min-width: 992px)
  .modal-lg
    max-width: 900px
@media (max-width: 768px)
  .modal-lg
    max-width: 600px
    margin: 30px auto
.upload-form
  margin: 10px auto
  width: 300px
  .upload-file-form
    margin-bottom: 10px
.configuration-modal-body
  .rosco-tabs.nav-tabs
    padding: 10px 0px 7px
    background: #f5f5f5
    margin: -15px -15px 15px
  .log-status
    text-align: center
    font-size: 1rem
  .logs-body
    margin-top: 10px
    font-size: 1rem
    .log-options
      width: 75%
      margin: auto
      label
        display: block
    .logs-checkbox
      height: 15px
      width: 15px
    .log-type
      position: relative
      bottom: 2px
      margin-left: 3px
      font-weight: normal
  .item-logs
    font-size: 1rem
    .control-label
      margin-right: 5px
  .upload-file-form
    margin: 10px auto
    width: 300px
  .reboot-label
    text-align: center
    label
      width: 300px
      text-align: left
  .date-container .rdtPicker
    bottom: 35px
.configuration-modal-footer
    text-align: right
    margin-top: 15px
    .rosco-primary.inverted
      margin-right: 10px

.configuration-button-wrapper
  @media (max-width: 991px)
    float: left
    width: 100%
    padding: 10px 0
    .configuration-modal-button
      margin-right: 0
  @media (max-width: 500px)
    text-align: center

@media (max-width: 500px)
  .audit-view-modal,
  .reload-dates-mobile
    .rdtPicker
      right: 0

.account-settings
  .user-settings-label
    font-weight: 100
    font-size: 16px
    margin: 0px 3px 0px 7px
  .toggle-buttons
    display: inline
    .btn-default
      min-width: 60px
      width: min-content
      background-color: #f0f0f0
      border-color: #f0f0f0f0
      color: #C0C0C0
      padding: 4px 0px
      &.active, &:hover
        border-color: var(--theme-colors-primary)
        background: var(--theme-colors-primary)
        color: #fff
  .user-timezone
    display: flex
    align-items: baseline
    .timezone-select
      flex-grow: 1
      margin-left: 3px
  .speed-units-control
    margin-top: 5px
    .speed-unit-label
      margin-left: 7px
      font-weight: bold
.partner-whitelabel
  .rc-color-picker-trigger.custom-style
    width: 90px
    height: 30px
  .wrapper-text-editor
    .toolbar-text-editor
      border-color: #ccc
    .editor-text-editor
      min-height: 120px
      max-height: 190px
      border: 1px solid #ccc
      padding: 5px
      border-radius: 2px
  .map-color-scheme-tree
    height: 200px
    overflow: auto
    pre
      display: inline-block
      margin-bottom: 0
  .upload-button-wrapper
    display: flex
    align-items: flex-start
    .image-favicon
      max-height: 50px
      max-width: 160px
      width: auto
      height: auto
    .image-logo
      max-height: 50px
      max-width: 160px
      width: auto
      height: auto
      margin-left: 20px
    .default-loader
      position: relative
      width: 100px
      height: 76px
      margin-left: 20px
      .loader
        display: block !important
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0
        background-position: 30px 27px
        i
          margin-top: 0

.manage-users-edit, .edit-asset-block, .add-asset-block, .fleet-settings-block, .add-group-block
    .form-group
        padding: 0px 10px 18px 0px
        .form-control
            border-radius: 1px
            border: 2px solid #C1C1B9

    form
        .form-group
            margin-bottom: 0
    a > img
        margin-right: 4px

.edit-company
  .company-info.subheader-section
    margin-top: 0px
    .external-service-wrapper
      display: inline-block
      margin-right: 10px
    #geotab-dropdown
      margin-right: 10px
      min-width: 185px
    .dropdown-body
      width: 450px
      padding: 0px 10px 5px
      font-size: 1rem
      &.lg
        width: 560px
      .info-section
        display: flex
        justify-content: space-between
        margin-bottom: 10px
        &.justify-left
          justify-content: left
        label
          font-weight: bold
          margin-right: 5px
      .button-link
        font-size: 1rem
        text-align: left
    .dropdown-footer
      background-color: #f0f0f0
      text-align: right
      margin-bottom: -5px
      padding: 5px 10px
      border-top: 1px solid #888
      display: flex
      justify-content: space-between
      button
        min-width: 100px
    @media (min-width: 992px)
      display: inline-block
    @media (max-width: 767px)
      .dropdown-body
        width: 250px
  .company-custom-apn
    .apn-name
      width: 60%
      margin-right: 30px

.user-notifications
  .notifications-container
    .title-flex
      display: flex
      justify-content: space-between
      align-items: center
      margin-bottom: 10px
      .category-title
        margin-bottom: 0px
    .category-subtitle
      @media (min-width: 992px)
        margin-left: 15px
    .notifications-grid
      margin-bottom: 20px
      .notification-wrapper
        border: 1px solid #ccc
        border-radius: 8px
        padding: 5px
        .toggles
          display: flex
          justify-content: space-between
          flex-wrap: wrap
          .notification-title
            font-size: 1.125rem
          .toggle-label
            font-size: 1rem
            margin-right: 5px
            vertical-align: middle
            border: 1px solid
            border-radius: 5px
            padding: 2px 4px
            color: var(--theme-colors-tertiary)
            cursor: pointer
            .edit-notif
              margin-right: 3px
              display: none
              position: relative
              bottom: 2px
          .react-toggle
            margin-right: 0px
        &:hover .toggles .toggle-label .edit-notif
          display: inline
    .dms-cutoff-select
      font-weight: 100
      float: right
      position: relative
      bottom: 3px
      select
        margin-left: 5px
      @media (max-width: 767px)
        float: none
        margin-top: 5px

.notifications-modal
  .agreement-modal-header
    text-align: center
    img
      height: 50px
      margin-bottom: 15px
  .agreement-modal-body
    height: 500px
    overflow: auto
    p
      font-size: 12px
  .agreement-modal-footer
    .btn-default
      width: 220px
      &.rosco-delete
        margin-left: 0px
        @media (max-width: 767px)
          margin-bottom: 5px

  .modal-body .notifications-modal-body
    .type-select
      margin-top: 10px
      .notif-type
        margin-right: 5px
      .phone-number
        margin-top: 10px
        .react-tel-input
          input
            box-shadow: none
            border-color: var(--theme-colors-primary)
          .flag-dropdown
            border-color: var(--theme-colors-primary)
    .sensor-wrapper
      border: 1px solid #ccc
      border-radius: 5px
      padding: 5px 12px 10px
      margin-top: 5px
      .sensor-title
        font-size: 1rem
        text-align: center
      .sensor
        display: flex
        .sensor-name
          flex-grow: 1
        .sensor-toggle
          width: 125px
          position: relative
          top: 14px
          text-align: right
          span
            margin-right: 5px
      .vehicle-search
        width: 100%

.modal-lg-size
  .modal-dialog
    max-width: 600px
    .modal-content
      width: 600px
    @media (max-width: 768px)
      max-width: 600px
      .modal-content
        width: auto
.email-report-modal
  .modal-dialog
    max-width: 80%
    .modal-content
      width: 100%
    @media (max-width: 768px)
      max-width: 100%
      margin: 10px

.rolloutBlacklistModal
  .modal-dialog
    .modal-content
      .contained-modal-title
        font-weight: 500
      .rolloutBlacklist-modal-body
        font-size: 14px
        font-weight: 450
        span
          color: red
      .select-box
        padding: 10% 10%
        .select-box-wrapper
          .search-bar-wrapper
            width: 80%
        .group-list
          .asset-select-wrapper
            padding-left: 15px
            strong
              padding-left: 5px
      .modal-footer
        .custom-button
          min-width: 115px

.config-profile-modal
  .modal-dialog
    .modal-content
      .main-content-seeHistory
        padding: 10px 0px 0px 0px
        max-height: calc(100vh - 16rem)
        overflow-y: auto
      .main-content-compareHistory
        padding: 0
        margin-top: 40px
        min-height: 350px
        max-height: calc(100vh - 23rem)
        overflow-y: auto
        .react-bs-table-container
          .react-bs-table-tool-bar
            .row
              --bs-gutter-x: 0
              .col-lg-8
                width: 100%
                padding-bottom: 15px
                .btn-group
                  width: 100%
                  .config-compare-group
                    width: 100%
                    display: flex
                    justify-content: space-between
                    align-items: center
      .modal-body
        label
          font-size: 18px
          span
            font-weight: bold
        .rosco-select
          max-width: 150px
          height: 42px
          margin: 10px 0
          .css-1jqq78o-placeholder,  .css-1dimb5e-singleValue
            margin-bottom: -10px

.modal-backdrop
  width: 100%
  height: 100%

.notifications-modal-body
  section
    .type-select
      .block_notification
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
      @media (max-width: 768px)
        .block_notification
          flex-direction: column

.react-toggle
    vertical-align: middle
    margin-right: 8px
    &.react-toggle--checked
      .react-toggle-track
        background-color: var(--theme-colors-tertiary)
      .react-toggle-thumb
        border-color: var(--theme-colors-tertiary)
      &:hover:not(.react-toggle--disabled)
        .react-toggle-track
          background-color: #2591bf
        .react-toggle-thumb
          border-color: #2591bf
.toggle-left-title
  margin-right: 8px

.list-box-wrapper
  margin-top: 20px
  @media (min-width: 992px)
    margin-left: 15px
  .label-wrapper
    display: flex
    justify-content: flex-start
    gap: 55px
    .control-label
      flex-basis: 50%
      margin: 0px 0px 5px

.col-md-1-7
  position: relative
  min-height: 1px
  padding-right: 10px
  padding-left: 10px
  height: 65px


.col-md-1-7
  width: 33%
  float: left

.active-dots, .non-active-dots
  width: 30px
  display: inline-block
  font-size: 12px
  cursor: pointer
  &:after
    content: ''
    display: inline-block
    border-radius: 50%
    background-color: #1fc80d
    width: 16px
    height: 16px

.active-dots
  &:after
    background-color: #1fc80d
.non-active-dots
  &:after
    background-color: #FF0000

.live
  &:after
    content: 'L'
    color: white
.gps
  &:after
    content: 'T'
    color: white
.ads
  &:after
    content: 'V'
    color: white


.change-password
  margin: 20px auto 0px
  .control-label
    color: var(--theme-colors-tertiary)
    font-weight: 400
    margin-bottom: 0px
  @media (min-width: 768px)
    width: 66%



@media (min-width: 768px)
  .col-md-1-7
    width: 25%
    float: left


@media (min-width: 992px)
  .col-md-1-7
    width: 20%
    float: left


@media (min-width: 1200px)
  .col-md-1-7
    width: 20%
    float: left

.audit-log-tbl
    tr
        font-size: 0.875rem

.modal-audit-lg
  width: 900px
@media (max-width: 1200px)
  .row>*
    flex-shrink: 0
    width: 100%
    max-width: 100%
    padding-right: calc(var(--bs-gutter-x) * .5)
    padding-left: calc(var(--bs-gutter-x) * .5)
    margin-top: var(--bs-gutter-y)
@media (max-width: 562px)
  .modal-dialog
    position: relative
    width: auto
    margin: 10px

.fleet-admin-data-usage, .modal-audit-log, .config-modal
  .date-container
    display: flex
    flex-direction: row
    margin-top: 5px
    margin-bottom: 5px
    text-align: center
    .date
      display: inline-block
      float: left
      line-height: 33px
      span
        margin-right: 5px
    .datetime
      display: inline-block
      width: 185px

.date-container-wrapper
  margin: 10px 0px
  text-align: center
  .date-container
    display: inline-flex
    justify-content: center
    align-items: center
    .date-label
      font-size: 1rem
      margin-bottom: 0px
      margin-right: 5px
    @media (min-width: 768px)
      & + .date-container
        margin-left: 15px
    @media (max-width: 767px)
      display: flex
      & + .date-container
        margin-top: 10px

.config-modal
  .live-logs-date-container .datetime
    display: flex
    justify-content: center
    width: 100%
    svg
      cursor: pointer
  .live-logs-container
    width: 90%
    height: 250px
    background-color: #f5f5f5
    overflow-y: auto
    margin: 15px auto 0px
    border: 1px solid #ccc
    border-radius: 5px
    padding: 5px
    font-size: 1rem
    .log-wrapper
      display: flex
      .log-timestamp
        margin-right: 5px
      .log-code
        font-weight: 500
        margin-right: 5px

@media (min-width: 501px) and (max-width: 991px)
  .fleet-admin-data-usage, .modal-audit-log
    .btn-default
      margin-right: 0
      margin-left: 145px


#sidemenu-tooltip
  z-index: 100002
  .tooltip-inner
    padding: 5px 12px
    font-size: 0.875rem

.custom-tooltips
  min-width: auto
  &.rssi-tooltips
    color: #000
    background-color: #fff
    left: -5px !important
    .tooltip-inner
      max-width: 300px
      color: #000
      background-color: #fff
    .tooltip-arrow
      display: none

.optional
  span
    font-size: 0.6875rem
    color: #a6a6a6

.upload-file-form
  background-color: #FFFFFF
  border: 1px solid #CCCCCC
  border-radius: 2px
  cursor: pointer
  height: 34px
  position: relative
  text-align: left
  vertical-align: middle
  width: 250px
  cursor: pointer
  z-index: 1

.upload-file-form .selectbutton
  background-color: var(--theme-colors-primary)
  border: 1px solid var(--theme-colors-primary)
  color: #FFFFFF
  float: left
  font-size: 1rem
  height: 100%
  line-height: 25px
  overflow: hidden
  padding: 2px 6px
  text-align: center
  vertical-align: middle
  width: 100px
  z-index: 1

.upload-file-form #upload-input,
.upload-file-form .upload-input
  position: absolute
  top: 0
  left: 0
  width: 250px
  -moz-opacity: 0
  filter: alpha(opacity=0)
  opacity: 0
  font-size: 0rem
  height: 30px
  z-index: 20
  cursor: pointer


.upload-file-form #upload-form-label,
.upload-file-form .upload-form-label
  background-color: #FFFFFF
  float: right
  height: 100%
  line-height: 25px
  overflow: hidden
  padding: 2px
  text-align: left
  vertical-align: middle
  width: 141px

  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.modal-custom
  .modal-body
    text-align: center

//* Display modal window over another modal window
.custom-modal-backdrop
  z-index: 10000
  &+ .modal
    z-index: 10000
  &.z-index-highest
    z-index: 1000000
    &+ .modal
      z-index: 1000000


.red-pagination-input
  border: 2px solid #e60b0b
  color: #e60b0b

.editing-editable-class, .invalid-editable-class
  textarea
    resize: none
    height: 36px
  button
    float: right

.dashboard-settings
  a
    text-decoration: none
    color: #000

.dashboard-settings
  a
    text-decoration: none
    color: #000

@media (max-width: 991px)
  .track-container-inner
    #block-tree
      width: 100% !important
      height: auto !important
      .devices-tree
        height: auto !important
      .sort-label
        margin-bottom: 10px
    .vehicles-online
      position: initial
    #map-block
      width: 100%
      margin-left: 0
      left: 0
      position: initial
      height: 800px
      overflow: hidden
      .map-element
        height: 800px !important
    #split-bar
      display: none

//BUTTONS
.btn.btn-default
  transition-property: background-color, border-color, color
  transition-duration: 0.3s
  &:focus, &:active, &:active:focus
    outline: none
  &.small
    min-width: 95px
    width: auto
  &.x-small
    font-size: 13px
    width: 100px
    padding: 1px 0px
  &.icon-button
    min-width: auto
    font-size: 28px
    line-height: 1
    padding: 2px 8px
.btn-link
  color: var(--theme-colors-tertiary)
  padding: 0px
  border: none
  background-color: transparent
  &:hover, &:active, &:active:focus
    background-color: transparent
    border-color: transparent
.red
  color: #ff0000
  margin-right: 15px
.export-button
  float: right
  width: auto
  height: 30px
  border-radius: 5px
  padding: 0px 11px
  font-size: 14px
  transition: background-color 0.3s
  display: flex
  align-items: center
  svg
    margin-right: 5px
  .export-label
    position: relative
    top: 1px

//SEARCH AND SELECT 
.vehicle-search, .rosco-select
  border-width: 0px 0px 2px 0px
  border-color: var(--theme-colors-primary)
  border-radius: 0px
  font-size: 1rem
  background-color: transparent
  box-shadow: none
  outline: none
  transition: border-color 0.3s
  appearance: auto
  &:focus
    outline: none
  &:focus, &:hover
    border-width: 0px 0px 2px 0px
    border-color: var(--theme-colors-tertiary)
  &:error
    border-color: #ff0000

//SEARCH
.vehicle-search
  width: 100%
  padding: 8px 0px 5px
  line-height: 1
  &:read-only
    background: #f0f0f0
    color: #888
    cursor: default
.search-bar-wrapper
  display: flex
  align-items: flex-end
  svg
    height: 1.625rem
    width: 1.625rem
    margin-left: 10px

//SELECT
.select-groups
  margin-top: -5px
.css-b62m3t-container
  padding-top: 0px !important
.rosco-select
  border-width: 0px 0px 2px 0px
  border-color: var(--theme-colors-primary)
  min-width: 185px
  max-width: 100%
  text-align: left
  border-width: 0px 0px 2px 0px
  border-color: var(--theme-colors-primary)
  border-radius: 0px
  padding: 8px 4px 2px 0px
  font-size: 16px
  background-color: transparent
  box-shadow: none
  outline: none
  cursor: pointer
  transition: border-color 0.3s
  .caret
    float: right
    margin-top: 10px
    border-width: 6px
    color: var(--theme-colors-primary)
  &:hover
    border-color: var(--theme-colors-tertiary)
    .caret
      color: var(--theme-colors-tertiary)
  &:disabled
    background-color: #f0f0f0
    border-color: #999999
    cursor: default
  &.block
    display: block
    width: 100%
  &.small
    min-width: initial
    width: 95px
  .basic-multi-select, .css-13cymwt-control
    border-radius: 0px
    border-width: 0px 0px 2px 0px
    border-color: var(--theme-colors-primary)
    height: auto
    transition: border-color 0.3s
    background-color: transparent
    
    // .css-1jqq78o-placeholder
    //   color: black
    //   padding-top: 9px
    .css-1xc3v61-indicatorContainer
      color: black
      svg
        width: 17px
        height: 17px
        margin-top: 5px
    &:hover
      border-color: var(--theme-colors-tertiary)
    .Select-placeholder, .select__placeholder
      line-height: 31px
      padding-left: 0px
      bottom: 0
      color: #aaa
      left: 0
      line-height: 34px
      padding-right: 10px
      position: absolute
      right: 0
      top: 0
      max-width: 100%
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
    .Select-input
      margin-left: 0px
      height: 27px
    .Select-value, .select__multi-value
      margin: 0px
      background-color: #ebf5ff
      // background-color: rgba(0, 126, 255, 0.08)
      border-color: var(--theme-colors-primary)
      color: #007eff
      // color: var(--theme-colors-primary)
      border-radius: 2px
      border: 1px solid #c2e0ff
      border: 1px solid rgba(0, 126, 255, 0.24)
      margin-right: 5px
      .select__multi-value__label
        padding: 3px 5px
        color: #007eff
        // color: var(--theme-colors-primary)
      .select__multi-value__remove
        border-left: 1px solid rgba(0, 126, 255, 0.24)
      .select-value-icon
        border-right-color: var(--theme-colors-primary)
      & + .Select-value
        margin-left: 2px
    .select__menu
      display: none
  &.is-focused:not(.is-open) .Select-control, &.is-open .Select-control
    box-shadow: none
    border-color: var(--theme-colors-tertiary)
  &.Select
    padding-top: 0px

// NUMBER INPUT
.number-input
  width: 80px
  text-align: center
  padding: 0
  &.disabled
    color: #bbb
    border-color: #bbb
    cursor: default
    background-color: #fff

//NAV TABS
.rosco-tabs.nav-tabs
  display: flex
  flex-wrap: wrap
  justify-content: center
  border-bottom: none
  margin-top: 8px
  padding: 15px 0px 7px
  background: #f5f5f5
  margin: -15px -15px 15px
  li button
    color: #333
    border: none
    background: transparent
    outline: none
    box-shadow: none
    padding: 2px 10px
    margin-right: 10px
    font-size: 1rem
    border-radius: 5px
    position: relative
    bottom: 2px
    &:hover
      text-decoration: underline
  li button.active
    background-color: #fff
    color: var(--theme-colors-primary)
    font-weight: 500
    &:hover, &:focus
      text-decoration: none
      border: none
  @media (max-width: 767px)
    li
        text-align: center
        flex-basis: 140px
.log-status
  text-align: center
  font-size: 16px
.log-options
  .form-group
    div
      display: flex
      flex-direction: column
      label
        input
          margin-right: 5px

//TABLE STYLES
.rosco-table
  overflow: auto
  //HEADER STYLES
  .rosco-table-header
    border-radius: 5px 5px 0px 0px
    overflow: hidden
    width: auto
    .table
      margin-bottom: 0px
      thead
        border: none
    th
      background-color: var(--theme-colors-primary)
      color: #fff
      font-size: 1rem
      font-weight: 300
      border-bottom: none
      line-height: 1.5
      .typeFilter
        cursor: pointer
      .typeFilter, .event-filter-toggle
        color: #fff
      .order
        .caret
          color: #fff!important
          display: inline-block
          width: 0
          height: 0
          vertical-align: middle
          border-right: 4px solid transparent
          border-left: 4px solid transparent
          border-top: 4px dashed
        .dropup, &.dropup 
          .caret
            border-top: 0
            border-bottom: 4px dashed
      & + th
        border-left: 1px solid #fff
  //BODY STYLES
  .rosco-table-body
    color: #666666
    width: auto
    tbody
      border: none
    .tr-column-odd-grey
      background-color: #f2f2f2
    .tr-column-even-white
      background-color: #fff
    td
      line-height: 2
      border-top: none
      border-bottom: 1px solid #ddd
      font-size: 0.875rem
      .button-link
        font-size: 0.875rem
      .btn-xs
        font-size: 0.875rem
        padding: 3px 4px
        min-width: initial
        width: 100%
        &:not(.rosco-primary.inverted)
          max-width: 105px
        &.widthAuto
          max-width: initial !important
      .custom-button.icon
        border: none
        svg
          height: 1.25rem
          width: 1.25rem
        &.inverted svg
          height: 1.75rem
          width: 1.75rem
        &.transparent svg, &.play-button svg
          height: 1.375rem
          width: 1.375rem
      &.no-padding-column
        padding: 0px
        .expand-icon
          padding: 8px 1px 8px 8px
      .expand-icon
        border: none
        background-color: transparent
        padding: 0px
        svg
          height: 1rem
          width: 1rem
          transition: transform 0.3s
          vertical-align: middle
        &:hover
          color: var(--theme-colors-primary)
        &.rotate svg
          transform: rotate(90deg)
          color: var(--theme-colors-primary)
      .pagination-wrapper
        line-height: 1.5

  // *** adjustable columns ***
  .custom-table-header-container .table tr th
    border: none
    position: relative

    .custom-table-header-row
      vertical-align: middle
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      display: inline-block

      .custom-table-header-title
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

      .custom-table-header-line
        background-color: var(--theme-colors-primary)
        position: absolute
        user-select: none
        top: 0px
        right: -8px
        width: 16px
        height: 100%
        cursor: col-resize
        z-index: 0
        .th-border
          position: absolute
          background-color: #fff
          height: 50%
          width: 1px
          top: 25%
          &.first-line
            left: 3px
          &.second-line
            left: 7px
  
  //EMPTY TABLE
  td.react-bs-table-no-data
    background-color: #fff
    font-size: 1rem
    border-bottom: none

//PAGINATION STYLES
.pagination-wrapper
  display: flex
  justify-content: space-between
  font-size: 12px
  color: #666666
  .pagination-top-text
    margin-bottom: 5px
  .page-link
    padding-top: 0px
  .pagination-btn-group .button-link
    margin-left: 5px
    text-decoration: underline
    background-color: transparent
    font-size: inherit
    &.active
      color: var(--theme-colors-tertiary)
      text-decoration: none
  .pagination
    margin: 0
    justify-content: flex-end
  .pagination-go-to-page
    input
      margin-left: 15px
      text-align: center
      width: 4em
      border: 1px solid rgb(169,169,169)
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button
      -webkit-appearance: none
      margin: 0

    input[type="number"]
      -moz-appearance: textfield

    input.red-pagination-input
      border: 2px solid #e60b0b
      color: #e60b0b

  .pagination>li>a, .pagination>li>span
    padding-bottom: 0
    border: 1px solid transparent
    color: var(--theme-colors-tertiary)
    text-decoration: underline
    &:hover
      background-color: transparent
    @media (max-width: 767px)
      background-color: transparent
  .pagination>li:last-child>a
    padding-right: 0    

  .pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover
    background-color: transparent
    border-color: transparent
    color: var(--theme-colors-tertiary)

  .pagination>.active>a
    text-decoration: none
    cursor: default
  .pagination-right
    text-align: right
  @media (max-width: 500px)
    flex-wrap: wrap
    justify-content: center
    .pagination-right
      text-align: center

//SPECIFIC TABLE STYLES
.manage-companies-page-table
  .rosco-table-header,
  .rosco-table-body
    min-width: 600px
    .td-column-highlighted
      background-color: #ff00009c
      color: #fff
.audit-view-page-table
  .rosco-table-header,
  .rosco-table-body
    min-width: 800px
.manage-firmware-page-table .firmware-admin-table-wrapper
  .rosco-table-header,
  .rosco-table-body
    min-width: 900px
.manage-companies-page-table, .driver-page-table
  .rosco-table-header,
  .rosco-table-body
    min-width: 600px
    .td-column-highlighted
      background-color: #ff00009c
      color: #fff
.manage-users-page-table
  .rosco-table-header,
  .rosco-table-body
    min-width: 800px
    .change-password-label
      margin-bottom: 0px
      margin-left: 3px
      vertical-align: middle
      font-weight: normal
.modem-page-table
  .rosco-table-header,
  .rosco-table-body
    min-width: 500px
    .modem-group-wrapper
      display: flex
      justify-content: space-between
.event-page-table
  .rosco-table-header,
  .rosco-table-body
    min-width: 1435px
  .blockWithDropdown
    position: relative
    .column-select-dropdown
      position: absolute
      right: 0px
      top: -5px
    @media (max-width: 991px)
      display: block !important

//DUAL LIST BOX
.react-dual-listbox
  input.rdl-filter
    border-radius: 1px
    border: none
    background-color: transparent
    border-bottom: 2px solid var(--theme-colors-primary)
    padding: 3px 0px
    margin-bottom: 5px
    outline: none
    font-size: 1rem
    transition: border-color 0.3s
    &:hover
      border-color: var(--theme-colors-tertiary)
  .rdl-control-container
    outline: none
  select.rdl-control
    min-height: 180px
    border-radius: 5px
    border: 1px solid #333
    background-color: #f0f0f0
    padding: 0px
    overflow: auto
    option
      padding: 2px 5px
  .rdl-actions
    .rdl-move
      border: 1px solid var(--theme-colors-primary)
      outline: none
      &:hover, &:active, &:focus, &:active:focus
        border-color: var(--theme-colors-primary)
      span
        color: var(--theme-colors-primary)

//DROPDOWN
.dropdown
  .no-caret::after
    display: none
.action-menu
  text-align: center
  padding: 0px
  .menu-action
    text-align: center
    padding: 0px
    text-align: center
    width: 100%
    display: block
    border: none
    background-color: transparent
    font-size: 1rem
    transition: background-color 0.3s
    & + .menu-action
      border-top: 1px solid #ccc
    &:hover
      background-color: #d0d0d0
    &.delete
      color: #f00

// DATE DROPDOWN
.date-dropdown
  margin-left: 10px
  flex-shrink: 0
  .custom-button
    font-size: 1rem
    padding: 0.375rem 0.875rem
  .date-select
    width: auto
    padding: 5px
    text-align: center
    .date-select-grid
      display: grid
      grid-template-columns: 1fr 1fr 1fr
      gap: 0.5em
      margin-bottom: 0.5em
      .custom-button
        margin: 0px
        &:focus, &:active
          background-color: var(--theme-colors-primary)
          color: #fff
          border-color: var(--theme-colors-primary)
    .manual-date-select, .date-select-grid
      .custom-button
        min-width: auto
        padding: 4px
  &.show .dropdown-toggle.rosco-primary
    background-color: #00446D
    border-color: #00446D
    color: #fff

// CALENDAR COMPONENT
.rdt
  input
    font-size: 1rem
    border: 2px solid var(--theme-colors-primary)
    color: var(--theme-colors-primary)
    background-color: #fff
    cursor: pointer
    text-align: center
    line-height: 1
  .rdtPicker
    padding: 0px
    border: none
    thead 
      tr
        background-color: var(--theme-colors-tertiary)
        color: #fff
      tr:first-child
        background: var(--theme-colors-primary)
        height: 45px
        .rdtSwitch
          color: #fff
          font-size: 1.25rem
          font-weight: 500
          padding-top: 2px
          &:hover
            background-color: var(--theme-colors-primary)
            opacity: 0.8
        .rdtNext, .rdtPrev
          color: #fff
          vertical-align: middle
          &:hover
            background-color: var(--theme-colors-primary)
    td.rdtActive
      background-color: var(--theme-colors-primary)
      border-radius: 5px
    .rdtDay
      &:not(.rdtDisabled)
        font-weight: 500
        font-style: normal
      &:hover
        border-radius: 5px
      &.rdtDisabled
        opacity: 0.9
    .rdtMonths td.rdtMonth, .rdtYears td.rdtYear
      font-size: 1.25rem
      &:hover
        border-radius: 5px
    .rdtTimeToggle
      font-size: 1rem
      padding: 5px
      background-color: #f0f0f0

//VEHICLE SELECT BOX
.select-box
  .select-box-header
    display: flex
    align-items: center
    margin-top: 5px
    .column-title
      font-size: 1rem
      font-weight: 600
  .select-all-checkbox
    margin: 0px 5px
    height: 16px
    width: 16px
  .select-box-body
    border: 1px solid #333
    padding: 2px 5px
    border-radius: 5px
    margin: 5px 0px
    background-color: #f0f0f0
    height: 275px
    overflow: auto
    &.no-checkbox
      padding: 0px
      .asset-select-wrapper
        display: block
        padding: 2px 5px
        cursor: pointer
        margin: 0px
        .asset-name
          margin: 0px
        &:hover
          background-color: var(--theme-colors-primary)
          color: #fff
    .asset-select-wrapper
      display: flex
      align-items: center
      margin: 2px 0px
      input[type=checkbox]
        height: 16px
        width: 16px
        margin: 0px
      .asset-name
        margin-left: 5px
        cursor: pointer
        position: relative
        top: 1px
    .group-list
      .asset-select-wrapper .asset-name
        &:hover
          text-decoration: underline
      .collapse-group
        opacity: 0
        transition: opacity 0.3s, transform 0.3s
        cursor: pointer
        margin-left: 5px
        color: #fff
        padding: 0px 2px
        &.rotated
          transform: rotateZ(180deg)
      &:hover .asset-select-wrapper .collapse-group
        opacity: 1
        background-color: #888
        border-radius: 5px
    .vehicle-list
      margin-left: 15px
      .asset-select-wrapper .asset-name:hover
        cursor: default
        text-decoration: none
    &.error
        border-width: 2px
        border-color: #ff0000
  .button-link
    padding: 0px
  &.disabled
    opacity: 0.5
    cursor: not-allowed
    .select-box-wrapper
      pointer-events: none

// EMPTY LIST
ul.no-style-list
  margin: 0px
  padding: 0px
  list-style-type: none

// CARD
.card
  border: 1px solid #ccc
  border-radius: 5px
  padding: 5px
  &.thin
    padding: 3px

// ALERT BUBBLE
.alert-bubble
  margin: 0px
  background-color: #fa3e3e
  border-radius: 30%
  color: #fff
  line-height: 1
  padding: 2px 3px
  font-size: 12px
  position: absolute

//LABELS
.online-label
  color: #6aca25
.parked-label
  color: #0071b6
.warning-label
  color: #f90
.error-label
  color: #f00

.asset-wrapper
  display: grid
  grid-template-columns: 3fr 2fr
  gap: 3em
  &.block
    display: block
  .asset-description
    // align-self: flex-start
    // position: sticky
    // top: 7.8rem
    margin: 0px
  @media (max-width: 767px)
    display: block
    aside.asset-description
      margin-top: 15px
.asset-description
  max-width: 90ch
  margin: auto
  .asset-paragraph, .custom-button
    font-size: 20px

.custom-classname-toggle
  cursor: pointer

  input[type="radio"]
    -webkit-appearance: checkbox /* Chrome, Safari, Opera */
    -moz-appearance: checkbox    /* Firefox */
    -ms-appearance: checkbox     /* not currently supported */

  .radio
    display: inline-block
    margin-top: 0
    margin-bottom: 0
    vertical-align: middle
    input[type=radio]
      position: relative
      margin-left: 0

  .radio__text
    display: inline-block
    padding-left: 3px
  .radio__text:first-of-type
    padding-right: 15px

.release-note-textarea
  width: 250px
  height: 80px
  max-height: 200px
  resize: vertical
  font-size: 1rem

.modem-uploader-container
  .panel-heading
    background-color: #f0f0f0
    padding: 10px 15px
    margin-top: 40px
    border: 1px solid #ddd
    border-radius: 5px 5px 0px 0px
    h5
      display: inline-block
      margin: 0px
  .panel-body
    border: 1px solid #ddd
    padding: 20px
  .modem-uploader-block
    margin-bottom: 20px
    float: none
    .autoselect-container
      .autoselect-block    
        display: inline-block
        position: relative
        padding-bottom: 24px
      .select-company
        display: inline-block
        margin-right: 10px
        @media (max-width: 767px)
          display: block
          text-align: left
          color: rgb(52,52,52)
          font-size: 1rem
        label
          margin-bottom: 0
          font-weight: 400
          margin-right: 5px
          color: #333
        select
          display: inline
          width: 200px
          height: 26px
          position: relative
          padding: 2px 5px
          @media (max-width: 767px)
            width: 100%

        .search-autocomplete-header
          text-align: left
          display: inline
          width: 250px
          position: relative
          @media (max-width: 767px)
            width: 100%
          input
            width: 100%
            height: 26px
            position: relative
            padding: 2px 26px 2px 5px
            @media (max-width: 767px)
              width: 100%
          i.fa
            width: 26px
            height: 26px
            line-height: 26px
            text-align: center
            margin-left: -26px
            position: relative
            z-index: 1
            float: right
            cursor: pointer
            &:hover
              color: #0E9BCE

          input.ie
            font-size: 1rem
          .items-list
              .item
                cursor: pointer
              .item-highlighted
                background-color: #00B0F2
                color: #ffffff

.fleet-admin-server-stats
  .report-header
    margin-top: 30px
    margin-bottom: 0
  .server-stats-row
    border-bottom: 1px solid #F0F0F0
    padding-bottom: 10px
    label
      font-weight: bold
  .stats-panel
    display: flex
    justify-content: space-between
    align-items: center
    padding: 5px 10px 5px 10px
    margin-bottom: 0px
    box-shadow: #ccc 0px 0px 6px 2px
    ul.daterange-control
      display: flex
      justify-content: space-between
      align-items: center
      margin: 0
      padding: 0 0 0 40px
      & > li
        display: inline
        padding: 5px
        a
          color: #000
          text-decoration: none
          font-size: 16px
          &:hover
            color: var(--theme-colors-tertiary)
        a.active
          color: var(--theme-colors-tertiary)
          font-weight: bold
  .refresh-buttons
    .dropdown-menu
      right: 0
      left: auto
      margin: 0
      padding: 0
      background-clip: padding-box
      border: 1px solid rgba(0,0,0,.15)
      border-radius: 4px
      box-shadow: 0 6px 12px rgba(0,0,0,.175)
      .checkbox
        label
          cursor: pointer
      .menu-item
        padding: 10px
      .menu-items
        padding: 0px 10px 10px 0px
        label
          height: 15px
          cursor: pointer
    input
      margin: 5px 5px 10px 5px
      position: relative
      top: 1px
    .btn
      background-color: #e6e6e6
      box-shadow: inset 0 3px 5px rgba(0,0,0,.125)
      border-color: #adadad
    .btn-primary
      color: black
      outline: 0
    .btn-primary:active
        outline: none
        outline: 0
    hr
      margin: 0
    .checkbox
      margin: 5px
    .radio
      margin: 0px 5px 0px 5px

.toggle-accordion
  padding: 1px 3px
  border: 1px solid #000
  margin-right: 10px

.modal-batch-function
  .modal-header
    .close
      opacity: 1
      color: #434343
      &:hover
        color: #FF0000

.info-tooltip
  color: var(--theme-colors-primary)
  position: relative
  left: 4px
  svg
    width: 0.875rem
    height: 0.875rem

th
  .info-tooltip
    color: #fff
    svg
      width: 16px
      height: 16px

.btn
  border: none

input, button, .btn
  outline: none

input[type="checkbox"]
  accent-color: dodgerblue

.firmware-expanded-wrapper
  overflow: auto
  .firmware-expanded-body
    min-width: 900px
    .upgrade-column
      .btnUpg
        border: 1px solid transparent
      .rosco-delete
        border: 1px solid #ff0000

.firmware-group-table
  table
    width: 100%
    tr
      td
        border: none!important
        white-space: normal
        padding: 8px
    .name
      width: 30%
      font-weight: 800
    .firmware
      width: 20%
    .reboot
      width: 15%
    .button-upgr
      width: 35%
      text-align: center

.manage-firmware-page-table .firmware-admin-table-wrapper
  .rosco-table-header,
  .rosco-table-body
    min-width: 900px
  .firmware-upload-container
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    @media (max-width: 767px)
      flex-direction: column
      button
        margin-bottom: 15px

.fleet-admin-firmware
  .firmware-portal-container .upload-file-form
    display: inline-block
    .help-block
      bottom: -22px
  .firmware-note-container .release-note
    width: 250px
    height: 80px
    max-height: 200px
    resize: vertical
  .firmware-portal-container, .firmware-send-container
    margin-top: 20px
  .firmware-portal-container, .firmware-note-container
    margin-bottom: 20px
  .firmware-wrapper
    display: flex
    justify-content: space-between
    margin-top: 15px
  @media (max-width: 767px)
    .firmware-wrapper
      flex-direction: column
  .firmware-portal-container, .firmware-note-container, .firmware-send-container
    @media (min-width: 768px)
      padding-left: 15px
      .upload-file-form
        margin-right: 10px
    @media (max-width: 767px)
      .upload-file-form
        margin-bottom: 15px
      .upload-file-form, .rosco-primary, .rosco-secondary, .release-note
        width: 100%
  .progress-wrapper
    .progress
      margin: 5px 0
      .progress-bar
        background-color: #337ab7
    .progress-status
      line-height: 14px
      font-size: 12px
  .actions-wrapper
    display: flex
    justify-content: center
    align-items: flex-start
    flex-direction: column
    button
      vertical-align: middle
      i
        vertical-align: middle

.firmware-container-mobile
  @media (max-width: 767px)
    .mobile-border
      border: 0 !important
    .firmware-mobile-wrapper
      display: flex
      padding-left: 25px
      padding-right: 0
      .firmware-inner
        padding-left: 0
        padding-right: 0

.firmware-container-mobile
    .search-bar-wrapper
        align-items: center
    .firmware-inner
      p
        margin-bottom: 10px
        &.upgrade-wrapper
            display: flex
            align-items: flex-end
            max-width: 600px
        .select-wrapper
            flex-grow: 1
        .button-wrapper
            margin-left: 10px
        .info-text
            font-weight: 500
        .react-toggle
            margin: 0px 8px
    .firmware-portal-container
        margin: 15px 0px
        .upload-file-form
            display: inline-block
            margin-right: 10px
    @media (max-width: 767px)
        .firmware-portal-container,
        .firmware-note-container,
        .firmware-send-container
            padding-left: 0
            padding-right: 0
        div
            padding-left: 0
            padding-right: 0
    @media (max-width: 500px)
        .firmware-send-container div
            width: 100%
        .firmware-note-container textarea
            width: 100%
        .firmware-portal-container
            .form-group, .upload-wrapper, .fields-container, select
                width: 100%
.firmware-update-block
    display: flex
    flex-direction: column
    align-items: center

.firmware-expanded-wrapper
    overflow: auto
    .rosco-table
        min-width: 900px

.rosco-table .firmware-group-table
    padding: 15px 0px 10px
    table
        width: 100%
        tr td
            border: none
            white-space: normal
            padding: 8px
        .name
            width: 30%
            font-weight: 800
        .firmware
            width: 20%
        .reboot
            width: 15%
        .button-upgr
            width: 35%
            text-align: center

.main-content-background-border
  padding: 15px
  @media (min-width: 768px)
    border: 15px solid #F0F0F0
  &.no-border
    border: none
    padding-top: 15px

.support-page
  .add-tutorial
    margin-bottom: 20px
    .add-video-block
      display: flex
      margin: 15px 0px
      .vehicle-search
        flex-basis: 50%
        margin-right: 2%
      .rosco-primary
        vertical-align: top
    @media (max-width: 767px)
      .add-video-block
        flex-wrap: wrap
        .vehicle-search
          flex-basis: 100%
        .rosco-primary
          margin-top: 10px
  ul
    list-style: none
    margin: 15px 0px
    padding-left: 0px
    font-size: 18px
    @media (min-width: 992px)
      margin-left: 15px
    li
      a
        i
          padding-right: 6px
        &:hover
          text-decoration: none
  .tutorial-video-grid
    display: grid
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr
    gap: 2em
    @media (min-width: 992px)
      margin-left: 15px
    .tutorial-video-card
      text-align: center
      border: 1px solid #f0f0f0
      background-color: #f0f0f0
      height: min-content
      &:hover
        text-decoration: none
      .video-link
        display: inline-block
        width: 100%
      .tutorial-thumbnail
        height: 200px
        padding: 30px
      .tutorial-info
        background-color: #fff
        padding: 10px
        text-align: left
        font-size: 22px
        display: flex
        justify-content: space-between
        align-items: center
        .tutorial-name
          color: #333
        .deleteButton
          height: 30px
          width: 30px
          color: #ff0000
          cursor: pointer
          display: none
        &:hover .deleteButton
          display: inline
        .rosco-primary-inverted
          width: 105px
          padding: 2px 0px
          float: right
    @media (max-width: 1199px)
      grid-template-columns: 1fr 1fr 1fr
    @media (max-width: 991px)
      grid-template-columns: 1fr 1fr
    @media (max-width: 767px)
      grid-template-columns: 1fr
      gap: 1em 0em

  // DROPDOWN TOGGLE
.dropdown-toggle
  &.no-caret
    &:after // hides carret/arrow for the bootstrap 5
      display: none
