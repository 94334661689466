.account-form, .reset-form, .change-password-form
    width: 700px
    margin: auto auto auto 150px
    -webkit-border-radius: 0
    -moz-border-radius: 0
    border-radius: 0
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    padding: 46px
    border-radius: 10px
    @media (max-width: 991px)
        margin: auto
    @media (max-width: 768px)
        width: 90%
        padding: 35px
        overflow: auto

.account-form
    background-color: #fff
    max-height: 500px
    .panel-heading
        padding: 0px 0px 20px
        background: transparent
        border: none
        .login-logo
            max-height: 45px
        .description
            font-size: 1.125rem
            margin-top: 6px
    @media (max-width: 767px)
        max-height: 90%
        height: 600px

.reset-form
  max-height: 385px

.import-form
    // width: 362px
    width: 550px
    margin: 0 auto
    -webkit-border-radius: 0
    -moz-border-radius: 0
    @media (max-width: 500px)
        width: 100%
    div
        margin: 20px 0px 10px
    .button-wrapper
        margin-top: 15px
        .custom-button + .custom-button
            margin-left: 10px
    .geotab-provision-link
        display: flex
        justify-content: center
        word-wrap: break-word
        // position: absolute
        // left: 5px
        // right: 5px
        // margin-top: 10px
        .clipboard-btn
            margin-left: 5px
            border: 1px solid black
    #login-button
        margin-right: 10px

.account-form
    .panel-body
        padding: 0px 46px 0px 0px
        display: inline-block
        width: 55%
        border-right: 1px solid #d0d0d0
        &.contact-section
            padding: 0px 0px 0px 46px
            width: 45%
            border: none
            vertical-align: top
            .login-subheader
                margin: 20px 0px
            .sales-link
                font-weight: 600
                display: inline-block
                font-size: 1rem
                svg
                    margin-left: 5px
        .login-header
            font-weight: bold
            margin: 15px 0px
            font-size: 24px
        .login-label
            visibility: hidden
            color: var(--theme-colors-tertiary)
            margin-bottom: 0px
            &.active
                visibility: visible
        .login-form + .change-password-submit
            text-align: center
            margin-top: 35px
            .custom-button + .custom-button
                margin-top: 15px
        .has-feedback
            margin-bottom: 0
        @media (max-width: 767px)
            width: 100%
            padding-right: 0px
            border: none
            &.contact-section
                width: 100%
                padding-left: 0px
    .account-form-heading
        font-size: 1.125rem
        margin-top: 30px
        .account-form-text
            font-size: 1rem
            margin-top: 15px


.password-container
    display: flex


.form-wrapper
    margin-top: 8px
    color: #666666


.form-wrapper
    label
        font-weight: normal


.form-wrapper
    .login-form + .login-form
        padding-top: 17px
        .checkbox
            padding-top: 10px
    .custom-button
        width: 100%
        font-size: 1rem
        padding: 10px 0px
    .checkbox label
        font-weight: 500
        color: #333
        input[type=checkbox]
            margin-right: 5px

.form-wrapper .has-error .help-block
    color: #f00



.form-group
    margin-bottom: 15px
    .fa
        font-size: 1rem
        color: #ccc
        top: auto
    .subscribers .Select-menu-outer
        display: none !important

.has-feedback 
    position: relative
    .fa-eye,
    .fa-eye-slash
        margin-top: 7px
        right: 5px
        cursor: pointer
        pointer-events: auto
        position: absolute
    & + .has-feedback
        margin-top: 15px

.forgot-password
    text-align: right
    font-weight: 500
    padding-top: 5px

.account-btn 
    padding: 10px 0
    text-align: center
    border: none
    background-color: var(--theme-colors-primary)
    color: #fff
    font-size: 16px
    width: 100%
    border-radius: 5px
    &.inverted
        background-color: #fff
        color: var(--theme-colors-primary)
        border: 2px solid var(--theme-colors-primary)

a
    color: var(--theme-colors-tertiary)
    text-decoration: none
    &:hover
        color: var(--theme-colors-tertiary)
        text-decoration: underline
    &.disabled
        pointer-events: none
        cursor: default
        color: #bbb


.form-wrapper input[type=text].error, .form-wrapper input[type=password].error
    border-color: #f00

.account-form.error
    .account-btn
        opacity: 0.3

.redux-toastr .top-right .toastr.animated
    .rrt-middle-container
        .rrt-title, .rrt-text
            // font-family: 'Roboto', sans-serif
            font-family: var(--theme-font)
            font-weight: normal
            font-size: 1rem
    &.rrt-info
        background-color: var(--theme-colors-tertiary)
    .close-toastr > *
        display: inline-block
        margin-top: 45%

.modal-dialog
    .modal-content
        .modal-header
            border-color: #c0c0c0
        .modal-title
            display: flex
            text-align: left
            font-size: 22px
            font-weight: 100
            &.action-title
                display: flex
                justify-content: space-between
                width: 100%
                .header-question
                    margin-top: -2px
                .rosco-primary-inverted
                    width: auto
                    padding: 2px 6px
                    margin-right: 3px
            &.delete-title
                color: #ff0000
            .sub-title
                font-size: 1.125rem
                margin-left: 30px
        .modal-body
            .modal-text
                font-size: 1.125rem
                white-space: pre-line
        .modal-footer
            border-top: 0
            text-align: right
            padding: 15px
            &.flex-center
                justify-content: center
            .btn.btn-default
                width: auto
                min-width: 95px
            .custom-button
                width: auto
                min-width: 95px
                // max-height: 35px
                & + .custom-button
                    margin-left: 5px
            .ok-button
                margin: 0px 10px
                @media (min-width: 768px)
                    margin-left: 139px

    .modal-body-custom
        max-height: calc(100vh - 200px)
        overflow: auto
        &.release-modal
            padding-bottom: 0px
            .sectionedit1
                position: relative
                top: -15px
                background: var(--theme-colors-primary)
                color: #fff
                margin: 0px -15px 10px
                padding: 15px
                border-radius: 5px 5px 0px 0px
                text-align: center
                font-size: 1.375rem
            .title
                text-align: center
                font-size: 22px
                font-weight: 500
                padding-bottom: 25px