*
  box-sizing: border-box

.right-content-big-block
  margin-left: 210px
  width: calc(100% - 210px)
  transition: margin-left 0.3s, width 0.3s
  .loader, .grey-header
    left: auto
    width: calc(100% - 210px)
  &.wider
    margin-left: 85px
    width: calc(100% - 85px)
    .loader, .grey-header
      width: calc(100% - 85px)
  &.full
    margin-left: 0
    width: 100%
    .loader, .grey-header
      width: 100%
  @media (max-width: 768px)
    margin-left: 0px
    width: 100%
    &.wider
      margin-left: 0px
      width: 100%
    .loader, .grey-header, &.wider .loader, &.wider .grey-header
      width: 100%

//CONTAINER, IMAGE, ARROW
.sideMenu
  position: fixed
  overflow: hidden visible
  top: 0
  left: 0
  height: 100%
  background-color: #f0f0f0
  z-index: 10
  transition: width 0.3s
  .header-logo-container
    background: #e0e0e0
    height: 4.2rem
    border-bottom: 1px solid #ccc
    display: flex
    justify-content: center
    align-items: center
    .header-logo
      width: 175px
      height: 57px
      padding: 0 3px
      display: inline-block
      background-color: #fff
      background-image: var(--header-logo-extended) //url(../../../../../../public/images/rosco-logo-horizontal.png)
      background-size: contain //146px 40px
      background-position: center //14px 10px
      background-repeat: no-repeat
      border-radius: 5px
      transition: width 0.3s
    @media (max-width: 767px)
      display: none
  .expand-wrapper
    position: fixed
    top: 81px
    left: 194px
    color: #fff
    visibility: hidden
    cursor: pointer
    transform: rotate(180deg)
    z-index: 100002
    transition: left 0.3s, top 0.3s
    svg
      background-color: var(--theme-colors-primary)
      border-radius: 50%
    @media (max-width: 767px)
      display: none
  &.sideMenuMin
    .expand-wrapper
      top: 73px
      left: 68px
      transform: none
    .header-logo
      width: 56px
      background-image: var(--header-logo)
      background-repeat: no-repeat
      background-position: center //10px 10px
      background-size: 80%
  @media (min-width: 768px)
    z-index: 100001
  @media (min-width: 768px)
    &:hover
      box-shadow: 1px 0px 0px var(--theme-colors-primary)
      .expand-wrapper
        visibility: visible

//SIDE MENU
.sideMenu
  width: 210px
  height: 100%
  .sideMenu-list
    padding-left: 0
    margin-bottom: 0px
    li
      list-style-type: none
  .menu-item
    width: 100%
    color: #333
    z-index: 5
    outline: none
    white-space: wrap
    transition-property: color, background-color
    transition-duration: 0.2s
    display: flex
    align-items: center
    padding: 7px 0px 7px 12px
    text-decoration: none
    &:hover
      color: var(--theme-colors-primary)
    &:hover, &:active, &:focus, &:active:focus
      text-decoration: none
    &.active
      background-color: var(--theme-colors-primary)
      color: #fff
    svg
      flex-shrink: 0
    .text
      font-size: 16px
      padding-left: 7px
    .bubble-icon
      height: 6px
      width: 6px
      border-radius: 50%
      background-color: #f00
      margin-top: -12px
      margin-left: 4px
  .collapsible
    .trigger .menu-item
      cursor: pointer
      &::after
        margin-left: 5px
        content: ""
        border-top: .4em solid
        border-right: .4em solid transparent
        border-bottom: 0
        border-left: .4em solid transparent
        transition: transform 0.3s
    &.collapsible-open
      .trigger .menu-item::after
        transform: rotateZ(180deg)
    .collapsible-menu .menu-item
      padding-left: 24px
  //SIDE MENU SMALL
  &.sideMenuMin
    .menu-item
      justify-content: center
      padding: 10px 0px
    @media (min-width: 768px)
      width: 85px
      .collapsible-menu .menu-item
        padding-left: 0px
      .trigger .menu-item
        flex-direction: column
        justify-content: center
        &::after
          margin-left: 0px
          margin-top: 5px
      .text
        display: none

//MOBILE SIDE MENU
.sideMenu, .sideMenu.sideMenuMin
  @media (max-width: 767px)
    width: 0px
    height: calc(100vh - 47px)
    transition: width 0.5s
    top: 47px
    padding-top: 0
    background-color: #ffffff
    z-index: 10000
    position: fixed
    overflow: hidden auto
    &.menuOpenMobile
      width: 100%
    .menu-item
      justify-content: flex-start
      padding-left: 15px
      &:not(.active)
        color: var(--theme-colors-primary)

.hide
  display: none

.show
  display: block

.btn-group
  display: inline-flex !important