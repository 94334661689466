.manage-vehicles, .event-page-table
	//SUBHEADER
	.manage-vehicles-subheader, .blockWithDropdown
		flex-wrap: wrap
		.subheader-section.search
			flex-basis: 20%
		.action-buttons
			.rosco-primary
				border: 1px solid
				border-top-color: var(--theme-colors-primary)
				border-bottom-color: var(--theme-colors-primary)
				padding: 7px 13px
				width: 150px
				&:first-child
					border-left-color: var(--theme-colors-primary)
				&:last-child
					border-right-color: var(--theme-colors-primary)
				&:hover
					border-color: #00446D
					border-left-color: #fff
					border-right-color: #fff
					color: #fff
				&:first-child
					border-left-color: #00446D
				&:last-child
					border-right-color: #00446D
				&.disabled
					background-color: var(--theme-colors-primary)
					border-color: var(--theme-colors-primary)
					color: #fff
					border-left-color: #fff
					border-right-color: #fff
					opacity: 0.65
					&:first-child
						border-left-color: var(--theme-colors-primary)
					&:last-child
						border-right-color: var(--theme-colors-primary)
		.column-select-dropdown
			flex-basis: 20%
			.dropdown-toggle
				float: right
				&.show::after
					transform: rotate(180deg)
			.vehicle-select-dropdown
				width: 100%
				text-align: right
				color: var(--theme-colors-primary)
				padding: 0px
				height: 34px
				background-color: transparent
				outline: none
				border: none
				box-shadow: none
			.vehicle-select-menu
				width: 350px
				input, i
					margin-right: 5px
				i
					color: red
		@media (max-width: 991px)
			display: flex
			.subheader-section.search
				flex-basis: 100%
			.action-buttons
				flex-basis: auto
				flex-grow: 1
				.rosco-primary
					width: auto
					flex-grow: 1
			#vehicle-select-dropdown
				flex-basis: auto
				margin-top: 10px
				margin-left: 10px
		@media (max-width: 767px)
			.column-select-dropdown
				display: none


	.fleet-activity-status
		.status-row
			display: flex
			align-items: center
			margin-bottom: 8px
			.icon
				width: 8px
				height: 8px                    
				margin-right: 6px                    
				border-radius: 50%
				&.driving
					background: #0FA739
				&.parked
					background: #2E318C
				&.awake
					background: #29A9E0
				&.offline
					border: 1px solid #000
				&.disabled
					background: #C2C2C2
				&.sleep
					background: url(../../../../../../public/images/ico/sleep_mode.png) no-repeat center center
					background-size: contain
					width: 16px
					height: 16px
					border-radius: initial
					& ~ .title
						min-width: initial
						width: 51px !important
					// width: 9px
					// height: 9px
					// box-shadow: #000 3px 2px 0px 0px
					// transform: rotate(133deg) translate(-5px, -2px)
					// margin-right: 5px
				&.pending_awake
					background: #8C36E0
				&.pending_sleep
					background: #333333
			.title
				min-width: 60px
				margin-right: 10px
				text-transform: capitalize
			.status-time
				margin-left: 10px

	//AT A GLANCE HEADER
	.at-a-glance
		.cards-wrapper
			display: flex
			width: 60%
			.card
				border: 1px solid #000
				// height: 300px
				width: 33%
				margin-right: 20px
				margin-bottom: 15px
				border-radius: 10px
				padding: 10px
				&.card-error
					border-color: #F12B2B
					.header
						color: #F12B2B
				.header
					font-size: 18px
					text-align: center
				.body
					margin-top: 10px
					// height: 200px
					// margin-bottom: 10px
					margin-bottom: 0
					overflow: auto
		.fleet-activity-status
			padding: 8px 10px 28px
			columns: 2
			.status-row .title
				width: 60px
		.total-cameras
			text-align: center
			font-size: 2rem
			font-weight: 300
			line-height: normal
		.ellipse-graph
			background: url(../../../../../../public/images/data-usage-ellipse.png) no-repeat center center
			width: 120px
			height: 120px
			position: relative
			margin: 0 auto 8px
			background-size: 120px
			overflow: hidden
			.radius
				height: 0px
				width: 50%
				border: 1px solid black
				top: 50%
				position: absolute
				transform-origin: right center
			.data-usage
				position: absolute
				width: 80px
				height: 80px
				background: #fff
				top: calc(50% - 40px)
				left: calc(50% - 40px)
				border-radius: 50%
				font-size: 12px
				font-weight: 500
				display: flex
				flex-direction: column
				justify-content: center
				align-items: center
				text-align: center
		.data-pool-wrapper
			display: flex
			align-items: center
			justify-content: center
			.total-data-pool
				.value
					text-align: center
					font-size: 1.4rem
					font-weight: 300
			svg
				font-size: 32px
				color: #FF9901
				margin-left: 15px
				&.alert-error
					color: #F12B2B
		.camera-health
			text-align: center
			display: flex
			flex-direction: column
			justify-content: center
			.camera-health-row
				margin-bottom: 15px
				.health-status
					width: 90px
					margin-right: 25px
				.value
					font-size: 18px
		@media (max-width: 1560px)
			.cards-wrapper
				width: 80%
		@media (max-width: 1274px)
			.cards-wrapper
				width: 100%
		@media ( max-width: 767px )
			.cards-wrapper
				display: block
				width: 100%
				.card
					width: 100%
	// .at-a-glance
	//     margin: 0px auto
	//     padding: 0px
	//     min-width: 290px
	//     width: 60%
	//     max-width: 600px
	//     @media ( max-width: 767px )
	//         width: 95%
	//     .at-a-glance-header
	//         border: 1px solid #ccc
	//         border-radius: 5px 5px 0px 0px
	//         padding: 7px
	//         text-align: center
	//         font-weight: 600
	//         background: #fff
	//     .icon-grid
	//         padding: 8px 5px
	//         border-left: 1px solid #ccc
	//         border-right: 1px solid #ccc
	//         background-color: #f0f0f0
	//         color: var(--theme-colors-primary)
	//         display: flex
	//         justify-content: center
	//         align-items: center
	//         .icon-wrapper
	//             width: 25%
	//             position: relative
	//             text-align: center
	//             &.error-crit
	//                 fill: #f00
	//             &.error-warn
	//                 fill: #FB9E14
	//         svg
	//             cursor: pointer
	//             vertical-align: middle
	//     .at-a-glance-message
	//         text-align: center
	//         padding: 20px 5px
	//         font-size: 20px
	//         color: #aaaaaa
	//         border-left: 1px solid #ccc
	//         border-right: 1px solid #ccc
	//     .at-a-glance-footer
	//         padding: 5px 7px
	//         border: 1px solid #ccc
	//         border-radius: 0px 0px 5px 5px
	//         background: #fff
	//         .status-label
	//             margin-left: 10px
	//     @media (max-width: 767px)
	//         .total-assets
	//             display: block

	//COLUMN FILTER DROPDOWN
	.dropdown-toggle.typeFilter
		color: #fff
		float: none
		&:hover, &:focus, &:active
			color: #fff
	.dropdown-menu
		color: #333
		font-size: 14px
		padding: 5px
		.groupName
			margin-left: 3px
		.clear-all-container
			margin-top: 8px
			text-align: center
		.clear-all
			padding: 2px 20px

	.add-asset-block
		margin-top: 15px

	.vehicle-table-header
		margin: 15px 10px 0px

	//TABLE STYLES
	.manage-assets-table
		margin-top: 8px
		position: relative
		.react-bs-table-tool-bar
			position: absolute
			right: 0px
			top: -38px
		.manage-vehicles-table
			border-radius: 5px 5px 0px 0px
			// overflow: auto hidden
			// overflow: inherit
			.device-checkbox
				height: 20px
				width: 20px
				margin-right: 5px
			.manage-vehicles-table-header,
			.manage-vehicles-table-body
				// min-width: 1215px
			.manage-vehicles-table-header
				// overflow: visible
				th:first-child .device-checkbox
					margin-top: 0px
					transform: translateY(4px)
				.camera-name-label
					// display: inline
				.with-filter
					padding-top: 0px
					padding-bottom: 0px
					// overflow: visible
					line-height: 1
					svg
						height: 1.75rem
						width: 1.75rem
					.filter-label
						margin-bottom: 0px
						// overflow: hidden
						text-overflow: ellipsis
						vertical-align: middle
					.filter-dropdown
						display: inline-block
						.typeFilterMenu
							.groups-header
								display: flex
								flex-direction: row
								justify-content: center
								align-items: center
								.order
									.caret
										cursor: pointer
										color: #0071b6 !important
										margin: 0px 0px 5px 5px
					@media ( max-width: 1500px )
						padding-top: 5px
						.filter-label
							text-align: center
							display: block
						.filter-wrapper
							text-align: center
							display: flex
							align-items: center
						.dropdown.btn-group
							display: block
						.device-checkbox
							position: relative
							top: 1px
						&.sort-column
							padding: 0px
							padding-left: 9px
			.manage-vehicles-table-body td
				text-overflow: clip
				line-height: 1.42857143
				.camera-name-wrapper
					display: flex
					align-items: center
					margin: 4px 0px
					.device-checkbox
						margin-top: 0px
						flex-shrink: 0
				.btn-link
					font-size: 13px
				.data-usage-link
					color: #f00
					.content
						margin-right: 3px
				.device-data-usage
					display: flex
					align-items: center
					justify-content: space-between
					svg
						font-size: 22px
						fill: #FB9E14
//ADD VEHICLES MODAL
.manage-vehicles-modal .add-vehicles-container
	margin: 10px 0px
	.new-vehicle-wrapper
		display: flex
		flex-direction: column
		align-items: baseline
		margin: 3px 0px
		padding: 0 35px
		.active-channel-3-container
			padding-top: 15px
			span
				margin-right: 20px
				font-weight: 500
		div
			font-size: 16px
			margin-right: 5px
		input
			flex-basis: 50%
			margin: 8px
		.x-small
			width: 25px
			flex-shrink: 0
			padding: 0px
			font-size: 20px
			font-weight: bold
			border-width: 1px
		.empty-div
			width: 25px

//PAIR DEVICE
.modal-pair-device
	.toggle-wrapper
		display: flex
		justify-content: space-between
		align-items: center
		flex-wrap: wrap
		border: 1px solid #C0C0C0
		border-radius: 8px
		padding: 5px 10px
		flex-wrap: wrap
		margin-bottom: 10px
		&:last-child
			margin-bottom: 0px
	.button-group
		button
			outline: none !important
		.toggle-button
			// min-width: 60px
			width: 200px
			// background-color: #f0f0f0
			// border-color: #f0f0f0f0
			&.active, &:hover
				border-color: var(--theme-colors-primary)
				background: var(--theme-colors-primary)
				color: #fff

@keyframes pending
	0%
		opacity: 100%
	75%
		opacity: 0%
	100%
		opacity: 100%
