body
  -ms-overflow-style: scrollbar
  // font-family: 'Roboto', sans-serif
  font-family: var(--theme-font)
  background-size: cover
  background-repeat: no-repeat
  background-attachment: fixed
  background-position: center
  @media (max-width: 767px)
    background-color: rgb(241, 245, 248)
    &.hasMobileMenu
      overflow: hidden

body.body-bg
    background-image: url(../../../../public/images/login/background.jpg)
    &.bg-1
      background-image: url(../../../../public/images/login/background-1.jpg)
    &.bg-2
      background-image: url(../../../../public/images/login/background-2.jpg)
      background-position: bottom
    &.bg-3
      background-image: url(../../../../public/images/login/background-3.jpg)
    &.bg-4
      background: url(../../../../public/images/login/background-4.jpg)
      background-size: cover
      background-position: bottom
      &.mobile
        background-position: initial
    &.bg-5
      background-image: url(../../../../public/images/login/background-5.jpg)
      background-position: bottom
    &.bg-6
      background-image: url(../../../../public/images/login/background-6.jpg)
    &.bg-7
      background-image: url(../../../../public/images/login/background-7.jpg)

body.live-video-page
    background-image: none

body
  &.mobile
      label
          .react-toggle--disabled
              pointer-events: none

.jumbotron-custom
  border-radius: 6px
  padding-top: 48px
  padding-bottom: 48px
  color: inherit
  background-color: #eee

#wrapper
  position: relative
.right-content-big-block
  margin-top: 4.2rem
  background-color: #FFF
  @media (max-width: 767px)
    background-color: rgb(241, 245, 248)
    margin-top: 99px
.main-content-block
  padding-top: 20px 
  min-height: calc(100vh - 15rem)

//UNIFORM SPACING FOR ALL HEADERS AND CONTENT
.grey-header, .page-subheader, .subheader-nav, .main-content-block
  padding-left: 25px
  padding-right: 25px
  @media (max-width: 767px)
    padding-left: 15px
    padding-right: 15px

//HEADERS
.grey-header
  position: fixed
  top: 0px
  z-index: 9
  background-color: #e0e0e0
  padding-top: 14px
  padding-bottom: 14px
  border-bottom: 1px solid #ccc
  transition: width 0.3s
  .page-title
    font-size: 28px
    font-weight: 300
    margin: 0rem
  .page-subtitle
    font-size: 24px
    font-weight: 500
    border-left: 2px solid #333
    padding-left: 12px
    margin: 0em
    .subtitle-nav
      color: #333
      border: none
      background: transparent
      outline: none
      box-shadow: none
      padding: 2px 10px
      font-size: 20px
      border-radius: 5px
      min-width: unset
      &.active
        background-color: #fff
        color: var(--theme-colors-primary)
        font-weight: 500
      .bubble-icon
        position: absolute
        height: 6px
        width: 6px
        border-radius: 50%
        background-color: #f00
        margin-top: 3px
        margin-left: 1px
  //DESKTOP, TABLET
  @media (min-width: 768px)
    display: flex
    align-items: center
    height: 4.2rem
    .page-title
      margin-right: 12px
      line-height: 1
    .page-subtitle 
      transform: translateY(2px)
      .subtitle-nav 
        & + .subtitle-nav
          margin-left: 10px
        &:not(.active):hover
          text-decoration: underline
    .mobile-nav
      display: none
  ///MOBILE
  @media (max-width: 767px)
    padding-top: 7px 
    padding-bottom: 2px
    .page-title
      display: inline-block
      font-size: 24px
      line-height: 1.42857143
      vertical-align: middle
    .page-subtitle
      display: flex
      justify-content: center
      border-left: none
      border-top: 2px solid
      padding: 6px 0px 1px
      margin-top: 6px
      text-align: center
      font-weight: 300
      line-height: 1.42857143
      .subtitle-nav
        margin: 4px 0px
        flex-basis: 50%
        font-size: 1rem
  // MOBILE NAV
  .mobile-nav
    z-index: 10000
    border: none
    padding: 0
    background: none
    vertical-align: middle
    line-height: 0
    transform: translateX(-5px)

// SUBHEADER
.page-subheader
  background-color: #fff
  border-bottom: 1px solid #ccc
  padding-top: 10px
  padding-bottom: 10px
  min-height: 55px
  .custom-button.icon 
    &:not(.inverted)
      padding: 0.375rem
  .custom-button.icon
    &:not(.inverted)
      padding: 0.375rem
  .subheader-text-container
    padding: 6px 0px 3px
    .subheader-text
      display: inline-block
      margin-right: 10px
      font-size: 18px
  .subheader-section
    &.search
      &.w-50
        width: 50%
      @media (min-width: 992px)
        width: 30%
    .rosco-select
      font-size: 18px
    &.search-flex
      display: inline-flex
      align-items: flex-end
      min-width: 30%
      .search-bar-wrapper
        flex-basis: 100%
        margin-bottom: 2px
        .vehicle-search
          margin-left: 0em
      & > label, .btn-default
        flex-shrink: 0
      .vehicle-search
        margin-left: 5px
      & > label, button
        flex-shrink: 0
        margin-left: 10px
      .search-label
        margin: 0em
        font-size: 18px
        font-weight: 300
      &.has-error
        position: relative
        padding-bottom: 10px
        .help-block
          bottom: -10px
          left: 57px
      @media (max-width: 991px)
        margin: 0px
        width: 100%
    &.date-flex
      display: inline-flex
      align-items: center
      .search-label
        margin: 2px 5px 0px 0px
        font-size: 18px
        font-weight: 300
      @media (max-width: 991px)
        display: flex
        .rdt
          flex-grow: 1
    &.button-group .toggle-button-group
      min-width: 150px
    &.align-center
      align-self: center
    &:last-child:not(.search-flex):not(.search) //page-right-subheader
      flex-shrink: 0
      & > *
        display: inline-block
        & + *
          margin-left: 10px
      @media (max-width: 991px)
        & > *
          flex-basis: 50%
          .custom-button
            width: 100%
        label
          flex: 200px 0 1
          align-self: center
          margin-bottom: 0px
    @media (max-width: 991px)
      display: flex
      justify-content: center
      & + .subheader-section
        margin-top: 10px
      &.search
        .search-bar-wrapper
          flex-basis: 100%
  @media (min-width: 992px)
    display: flex
    justify-content: space-between
    &.align-right
      flex-direction: row-reverse
  @media (max-width: 991px)
    .dropdown .dropdown-toggle.small
      width: 100%

// SUBHEADER TABS
.subheader-nav
  display: flex
  justify-content: center
  padding-top: 10px
  &.justify-left
    justify-content: left
  .subtitle-nav
    min-width: unset
    color: #333
    border: 1px solid
    background: transparent
    outline: none
    box-shadow: none
    padding: 2px 10px
    font-size: 20px
    border-radius: 5px
    flex-basis: 15%
    & + .subtitle-nav
      margin-left: 20px
    &:hover
      background-color: #d4d4d4
    &:focus
      box-shadow: none
    &.active
      background-color: #fff
      color: var(--theme-colors-primary)
      border-color: var(--theme-colors-primary)
      font-weight: 500
  @media (max-width: 991px)
    .subtitle-nav
      flex-basis: 50%
      margin: 0px
      & + .subtitle-nav
        margin-left: 2%
@media ( max-width: 767px )
  .btn-menu
    flex-direction: column
    gap: 15px
    button
      width: auto
      max-width: 50%
      margin-left: 0 !important

.hide-subtitle
  @media ( max-width: 767px )
    margin-top: -49px

//PAGE LAYOUT
.two-column-grid
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 1em
  &.margin-top
    margin-top: 1em
  @media (min-width: 992px)
    .two-columns
      grid-column: 1 / 3
    &.with-margin
      margin-left: 15px
  @media (max-width: 991px)
    grid-template-columns: 1fr
    gap: 0.75em
    &.margin-top
      margin-top: 0.75em
.three-column-grid
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  gap: 1em
  .last-two-columns
    grid-column: 2 / 4
  .first-two-columns
    grid-column: 1 / 3
  @media (min-width: 992px)
    &.with-margin
      margin-left: 15px
  @media (max-width: 991px)
    grid-template-columns: 1fr 1fr
    gap: 0.75em
    .last-two-columns, .first-two-columns
      grid-column: 1 / 3
  @media (max-width: 767px)
    grid-template-columns: 1fr
    .last-two-columns, .first-two-columns
      grid-column: 1 / 2
.four-column-grid
  display: grid
  grid-template-columns: 1fr 1fr 1fr 1fr
  gap: 1em
  .last-two-columns
    grid-column: 2 / 4
  .first-two-columns
    grid-column: 1 / 3
  @media (min-width: 992px)
    &.with-margin
      margin-left: 15px
  @media (max-width: 991px)
    grid-template-columns: 1fr 1fr
    gap: 0.75em
    .last-two-columns, .first-two-columns
      grid-column: 1 / 3
  @media (max-width: 767px)
    grid-template-columns: 1fr
    .last-two-columns, .first-two-columns
      grid-column: 1 / 2
.two-three-one-column-grid
  display: grid
  grid-template-columns: 2fr 3fr 1fr
  gap: 1em
  .last-two-columns
    grid-column: 2 / 4
  .first-two-columns
    grid-column: 1 / 3
  @media (min-width: 992px)
    &.with-margin
      margin-left: 15px
  @media (max-width: 991px)
    grid-template-columns: 1fr 1fr
    gap: 0.75em
    .last-two-columns, .first-two-columns
      grid-column: 1 / 3
  @media (max-width: 767px)
    grid-template-columns: 1fr
    .last-two-columns, .first-two-columns
      grid-column: 1 / 2
.two-column-grid, .three-column-grid, .two-three-one-column-grid
  .toggles-container
    display: flex
    align-items: baseline
    &.align-top
      align-items: baseline
    .toggle-wrapper
      flex: 50% 1 1
      margin-bottom: 0px

section + section
  margin-top: 15px

//TITLES AND SUBTITLES
.category-title-wrapper
  display: flex
.category-title
  color: var(--theme-colors-secondary) 
  font-weight: 100
  margin: 0px
// CATEGORY HEADER
h3.category-title
  font-size: 27px
  margin-bottom: 10px
// MODAL HEADER
h4.category-title
  font-size: 20px
  margin-bottom: 0.5em
.modal-eula
  .modal-content
    padding: 10px 50px 0
    .modal-header
      display: initial
    .modal-body
      max-height: calc(100vh - 320px)
      border: 1px solid #ccc
    .modal-footer
      display: initial
// FILTER HEADER
h5.category-title
  font-size: 1.125rem
  margin: 0.125em 0 0.25em
  text-align: center
// SUBTITLE WITH BORDER
.category-subtitle
  font-size: 1rem
  border-bottom: 0.125rem solid #333
  margin-bottom: 0.675em
  padding-bottom: 0.25em
  font-weight: bold
  .subtitle-label
    margin: 0 0 0 1.875em
    font-weight: 300
// SUBTITLE WITHOUT BORDER
// .category-subheader
//   font-size: 0.875rem
//   font-weight: 400
//   margin-bottom: 0.675em

//ITEM LIST
.category-item
  width: 100%
  text-align: left
  display: block
  border: 1px solid #ccc
  border-radius: 5px
  font-size: 0.9375rem
  padding: 10px
  padding-right: 5px
  color: var(--theme-colors-primary)
  box-shadow: 0px 1px 5px 0px #D9D9D9
  background: #fff
  transition: box-shadow 0.3s
  .see-history-profile
    display: flex
    flex-direction: row
    justify-content: flex-start
    gap: 15px
    min-width: 185px
    @media (max-width: 767px)
      flex-direction: column
      align-items: flex-start
  & + .category-item
    margin-top: 5px
  &.thin
    padding: 3px 5px
  &.flex-item
    display: flex
    justify-content: space-between
    align-items: center
    .right-label
      font-weight: normal
      margin-bottom: 0px
    svg
      height: 1rem
      width: 1rem
  &:hover
    border-color: #E0E0E0
    box-shadow: 0px 4px 5px 0px #999

.text-center
  text-align: center
.text-right
  text-align: right

.container-fluid.advanced-web-player
    padding: 0
    margin: 0
.advanced-web-player
    .right-content-big-block
        padding-right: 0


.loader, .loaderModal
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1000000
    display: flex
    justify-content: center
    align-items: center
    background: rgba(255, 255, 255, 0.5)
    &.customLoader
      background: var(--loader-url) no-repeat scroll 50% center rgba(255, 255, 255, 0.5)
      background-size: auto
    .rosco-spinner
        font-size: 100px
        color: var(--theme-colors-primary)
        animation-duration: 1.25s
    .rosco-icon
        position: absolute
        height: 45px
.loader
    position: fixed
.loaderModal
    position: absolute
    border-radius: 6px

@-webkit-keyframes spin
    0%
        -webkit-transform: rotate(0deg)
    100%
        -webkit-transform: rotate(360deg)

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

.hr-footer
  hr
    margin-top: -1px
    border-top: 1px solid #999999
    width: 102%

.mainContent
  width: 100%

.content-block
  min-height: calc(100vh - 225px)


.main-left-side
  width: 30%
  display: inline-block
.main-right-side
  width: 70%
  display: inline-block

.left
  display: inline-table
  position: relative
  height: 100%
  max-width: 286px


.right
  display: inline-table
  min-width: 74%
  max-width: 100%
  padding-left: 24px

.container
  max-width: 1600px

.base-wrapper-container
  position: relative

.notify-container
    display: flex
    flex-direction: column
    flex-wrap: wrap
    justify-content: flex-end
    align-items: flex-start
    align-content: flex-start
    position: fixed
    top: 0
    right: 0
    .notify-item
      width: 250px
      margin: 5px 10px
      color: #FFF
      border-radius: 5px
    .notify-item:hover
      opacity: 0.8
      box-shadow: 0 0 10px 0 rgb(15, 15, 15)
    .notify-item > p
      font-family: 'Lora', serif
      margin: 10px
      opacity: .8
    .notify-item.success
      background-color: rgba(81, 163, 81, 0.4)
    .notify-item.error
      background-color: rgba(203, 100, 94, 0.8)
    .notify-item.info
      background-color: rgba(33, 150, 243, 0.8)
    .notify-title
      font-weight: 700

// CARD
.card-wrapper
  border: 1px solid #ccc
  border-radius: 5px
  padding: 8px
  .input-wrapper
    display: flex
    justify-content: space-between
    align-items: center
    flex-wrap: wrap
    &.justify-evenly
      justify-content: space-evenly    
