body.mobile
	#map-block
		height: 100%
	.modal-dialog
		margin-top: 54px

body:not(.mobile)
	@media (max-width: 767px)
		#map-block
			&.tripReplayPanelOpened
				height: auto
				.event-tracking
					position: initial
					float: left

// HOME PAGE MAP
#map-block
	height: calc(100vh - 250px)
	padding-right: 0
	.map-price-marker2
		cursor: pointer !important
		font-weight: bold
		background-color: #1bfa05
		color: red
		position: relative
		top: -37px
		left: -10px
		opacity: 0.8 !important

		// font-family: 'Roboto', sans-serif !important
		font-family: var(--theme-font)
		color: #fff !important
		// position: relative !important
		// /* width: 70px */
		height: 21px
		border-radius: 3px
		line-height: 22px
		background-color: #46b13c
		// /* background-color: #52ca46 */
		box-shadow: 0 2px 3px 0 rgb(0 0 0 / 7%)
		text-align: center
		font-size: 11px !important
		font-weight: 700
		user-select: none
		border-width: 1px
		border-style: solid
		border-color: #3e9435
		z-index: 1000000
		padding: 0 3px
	.map-price-marker2:hover
		opacity: 1
	.map-price-marker1
		cursor: pointer !important
		font-weight: bold
		background-color: #1bfa05
		color: red
		position: absolute
		top: 35px
		left: -10px
		opacity: 0.8

		// font-family: 'Roboto', sans-serif !important
		font-family: var(--theme-font)
		color: #fff !important
		// position: relative !important
		// /* width: 70px */
		height: 21px
		border-radius: 3px
		line-height: 22px
		background-color: #46b13c
		// /* background-color: #52ca46 */
		box-shadow: 0 2px 3px 0 rgb(0 0 0 / 7%)
		text-align: center
		font-size: 11px !important
		font-weight: 700
		user-select: none
		border-width: 1px
		border-style: solid
		border-color: #3e9435
		z-index: 1
		padding: 0 3px
		&:hover
			opacity: 1
		&.offline
			background-color: #555
			border-color: #555
		&.parking
			background-color: var(--theme-colors-alternate)
			border-color: var(--theme-colors-alternate)

	.map-price-marker1:hover
		background-color: red !important
		color: #fff
		border-color: #red !important
		z-index: 3
		opacity: 1 !important
	&.hybrid
		.map-price-marker
			background-color: #1bfa05
			color: #000
	.map-price-marker
		// font-family: 'Roboto', sans-serif
		font-family: var(--theme-font)
		opacity: 0.8
		color: #fff
		&:hover
			opacity: 1
		&.offline
			background-color: #555
			border-color: #555
		&.parking
			background-color: var(--theme-colors-alternate)
			border-color: var(--theme-colors-alternate)
	.gray-map
		width: 100%
		height: 100%
		top: 0
		opacity: 0.4
		background-color: #c0c0c0
		position: absolute

.vehicle-sidebar-toggle
	font-size: 20px
	z-index: 1
	outline: none
	height: 50px
	// width: 35px
	left: 0
	top: calc(50% - 25px)
	position: absolute
	box-shadow: -2px 2px 3px 0 rgba(0, 0, 0, 0.2)
	border: 1px solid #888
	border-left-width: 0
	border-top-right-radius: 10px
	border-bottom-right-radius: 10px
	padding: 0 4px
	background-color: #fff
	color: var(--theme-colors-primary)

#split-bar
	background: #fcf9f9
	width: 12px
	cursor: w-resize
	z-index: 1
	right: 0
	position: absolute
	height: 100%
	border: 1px solid #b3b3b3
	.bar-toggle
		position: absolute
		height: 50px
		width: 12px
		border: 1px solid #BBB
		background-color: #BBB
		top: calc(50% - 25px)
		cursor: pointer
		left: -1px
		z-index: 10

.vehicle-list-wrapper
	@media (min-width: 992px)
		//12 because of the split-bar
		// margin-right: 12px
		border-right: 2px solid #ccc
	.fleet-activity-status
		.header
			border-bottom: 1px solid #000
			background: #F0F0F0
			padding: 4px 10px
		.body
			padding: 8px 10px 10px 20px
			columns: 2
			border-bottom: 1px solid #C0C0C0
			.status-row .title
				width: 60px
	.search-bar-wrapper
		margin: 10px 10px 15px !important
	.vehicles-sort-wrapper
		padding: 0 10px 15px
		border-bottom: 2px solid #000
		h5
			margin: 0
		.vehicles-sort-filter
			display: flex
			align-items: baseline
			position: relative
			select
				margin-right: 30px
			.event-filter-toggle .filter-dot
				position: absolute
				right: -16px
	.vehicles-list
		padding: 0px 15px
		@media (min-width: 992px)
			padding: 0px 8px
	.vehicles-online-header
		color: #fff
		font-size: 18px
		padding: 6px 0px
		background-color: var(--theme-colors-primary) //var(--theme-colors-primary)
		margin: 0px 0px 10px
		line-height: 1.42847143
		font-weight: normal
		text-align: center
		white-space: nowrap
	.search-bar-wrapper
		margin-bottom: 10px
	.devices-tree
		overflow: auto
		.tree-view_item
			margin-bottom: 4px
			.node
				font: 18px Roboto, sans-serif
		.tree-view_arrow
			display: none
		.tree-view_children
			margin-left: 10px
		.tree-node-container
			cursor: pointer
			line-height: 24px !important
			position: relative
			display: block
			width: 100%
			text-align: left
			border: none
			background-color: transparent
			padding: 0px
			overflow-x: auto
			&.active
				.tree-node-header.category-item
					border-radius: 3px
					border: 1px solid #29A9E0
					background: rgba(41, 169, 224, 0.2)

			// 	outline: none
			// 	margin-bottom: 5px
			// 	&:hover
			// 		background: transparent
			// 	.tree-node-header
			// 		font-weight: 500
			// 		border: 1px solid #ccc
			// 		border-radius: 5px 5px 0px 0px
			// 		border-bottom: none
			// 		background: #f0f0f0
			// 		.vehicle-name
			// 			font-weight: 500
			// &:not(.active)
			// 	transition-property: background-color, color
			// 	transition-duration: 0.2s
			// 	border-radius: 5px
			// 	&:hover
			// 		background-color: var(--theme-colors-tertiary)
			// 		.tree-node-header
			// 			color: #fff
			.tree-node-header
				// color: #888
				margin: 0px
				font-weight: normal
				font-size: 13px
				// line-height: 1.2
				padding: 4px 5px
				.vehicle-name
					line-height: 16px
				.status-row
					.icon
						margin-right: 5px
						min-width: 8px
				&.category-item 
					border: none
				.status-time
					color: #333
					font-size: 12px
					min-width: 90px
					line-height: normal
			&.online .tree-node-header
				color: #16be16
			&.parking .tree-node-header
				color: var(--theme-colors-alternate)
			.tree-node-body
				padding: 3px 5px
				font-size: 14px
				border: 1px solid #ccc
				border-radius: 0px 0px 5px 5px
				border-top: none
				background-color: #fff
				.node-icon-wrapper
					display: flex
					width: 100%
					svg
						flex-basis: 1.375em
				.node-label
					font-weight: 500
					margin: 0px 5px 0px 0px
				.node-icon
					position: relative
					top: 3px
					margin-right: 3px
				.address-button
					color: var(--theme-colors-primary)
					background-color: transparent
					border: none
					padding: 0px
					text-align: left
					svg
						flex-basis: 1.5em

.show-fleet-pannel
	#block-tree
		width: 300px
	#map-block
		left: 300px
	.vehicle-sidebar-toggle
		left: 300px
.show-vehicle-pannel
	#map-block
		left: 700px
	.vehicle-sidebar-toggle
		left: 700px

.chanels-list
	a
		padding: 5px 10px
		background-color: #fff
		color: var(--theme-colors-primary)
		text-decoration: none
		border: 2px solid var(--theme-colors-primary)
		border-radius: 5px
	li.active
		a
			background-color: var(--theme-colors-primary)
			color: #fff

.vehicle-parent
	button
		padding: 0 2px
		margin-right: 2px
.vis-item .vis-item-content
	padding-bottom: 10px
.alert-search
	position: fixed
	right: -70px
	z-index: 10
	-webkit-transform: rotate(90deg)
	transform: rotate(90deg)
	top: 144px
	button
		width: 159px
		height: auto

.date-container-alert-search
	.label-text
		margin-right: 10px
	.date-field
		display: inline-block
		.rdtOpen .rdtPicker
			position: fixed
			@media ( max-width: '767px' )
				position: absolute
				top: -240px

	@media (max-width: 991px)
		.label-text
			display: inline-block
			max-width: 25%
			margin-right: 0 !important
		.date-field
			max-width: 75%
		.text-right
			text-align: left
	@media (max-width: 767px)
		.label-text
			width: 15%
		.date-field
			width: 185px
		.btn-default
			max-width: 75%
			margin-left: 15% !important
		.date-field-item
			margin-bottom: 10px

.alert-search-container
	position: fixed
	left: 95px
	top: 75px
	z-index: 100003
	padding: 0
	input[type=text], select
		height: 24px
		font-size: 12px
		padding-top: 3px
		padding-bottom: 3px
	.modal-dialog
		margin: 0
	.modal-header
		padding: 8px 12px
		.close
			opacity: 1
			margin-top: 2px
			font-weight: normal
			font-size: 28px
	.modal-title
		font-size: 22px
		font-weight: 100
	.modal-body
		padding-top: 0
		padding-bottom: 0
		height: 530px
		overflow: auto
	.select-asset
		margin-top: 10px
		.checkbox
			margin: 0
	.asset-alerts-list > div
		padding-top: 3px
	.asset-alerts-list
		height: auto
		max-height: 185px
		overflow: auto
		margin-top: 5px
		margin-bottom: 5px
		border: 1px solid #ccc
		border-radius: 5px
		background: #fff
		display: grid
		grid-template-columns: 3fr 4fr 4fr 3fr
		padding: 2px 8px
		.alerts-label
			font-size: 16px
			color: var(--theme-colors-secondary)
			max-width: 100%
			font-weight: 700
			margin-bottom: 3px
		.checkbox
			margin: 2px 0px
			line-height: 14px
			font-size: 16px
			display: block
			label
				min-height: 12px
				margin-top: -2px
				font-weight: 400
				cursor: pointer
			input
				margin: 1px 5px 0 0
				height: 15px
				width: 15px
	.asset-alerts-list.fleet-search
		display: block
		padding: 5px 8px
		div:nth-child(3)
			display: grid
			grid-template-columns: 1fr 1fr 1fr 1fr
	@media (max-width: 767px)
		.asset-alerts-list
			display: block
			&.fleet-search
				padding: 5px 8px
				div:nth-child(3)
					display: block
		.asset-alerts-list .alerts-column
			margin-left: 8px
	.search-control
		button
			height: 25px
			padding: 0
			margin: 0
		input
			display: inline-block
			background-color: #fff
	.search-body
		padding: 8px
		border: 1px solid #ccc
		margin-top: 8px
		background-color: #f5f5f5
		border-radius: 5px
		.header
			font-size: 14px
			span
				cursor: default
				svg
					margin-top: -5px
			span.glyphicon
				padding: 1px 2px
	.search-result
		margin-top: 5px
	.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th
		padding: 2px 8px
		line-height: 1.4
		font-size: 14px

.pills-column
	width: 50%
	@media (min-width: 1199px) and (max-width: 1350px)
		width: 50%
	@media (min-width: 992px) and (max-width: 1036px)
		width: 50%

.alert-filters
	position: absolute
	top: 0
	text-align: right
	padding-right: 10px
	z-index: 1
	label
		font-size: 0.9em
	@media (max-width: 767px)
		position: initial
		margin-top: 10px
		padding-right: 15px
	.btn-event-search
		position: relative
		.btn
			margin-right: 0
		@media (min-width: 768px)
			padding-right: 0
		@media (max-width: 500px)
			.btn-default
				width: 100% !important
.search-autocomplete
	margin-bottom: 5px
	input
		width: 95%
	input.ie
		font-size: 16px
.search-autocomplete .items-list, .alert-search-container .search-autocomplete
	.item
		cursor: pointer
		padding: 2px 4px
		font-size: 14px
	.item-highlighted
		background-color: var(--theme-colors-tertiary)
		color: #ffffff
		padding: 2px 4px

.live-video-player
	max-width: 100%
	background-color: #000
	// .play-wrapper
	//     display: none
	.message
		position: absolute
		top: 44px
		color: #fff
		font-size: 18px
		width: 100%
		text-align: center
	.player-poster.stopped
		display: none !important
	.continue-watching
		height: 35px
		position: absolute
		bottom: 0px
		left: 0
		right: 0
		text-align: center
		background-color: rgba(255,255,255,0.5)
		button
			margin-top: 4px
	.container
		position: static
		background: none
		video
			max-width: 100%
			transform: scaleX(1.45)
			-ms-transform: scaleX(1.46)
			-o-transform: scaleX(1.45)
			-webkit-transform: scaleX(1.45)
			-moz-transform: scaleX(1.45)

.calibration-box
	width: 42%
	height: 63%
	border: 3px solid red
	position: absolute
	top: 14%
	left: 50%
	.cross-shape
		background: red
		height: 50px
		width: 10px
		position: absolute
		top: 45%
		left: 50%
		&:after
			background: red
			content: ""
			height: 10px
			width: 50px
			position: absolute
			top: 20px
			left: -20px


.live-video-channels
	position: fixed
	bottom: 0
	height: 26px
	left: 0
	right: 0
	text-align: center
	display: flex
	justify-content: space-between
	align-items: center
	height: 35px
	line-height: initial
	.chanels-list
		display: inline
		padding: 0
		margin: 0
		a.disabled
			background-color: #bbb
			border-color: #bbb
			color: #fff
	.rssi-signal
		margin-left: 10px
		color: var(--theme-colors-primary)
		cursor: pointer
		img
			margin-right: 5px
			width: 33px
	.calibration-btn
		padding: 3px 6px
		margin-right: 10px


.Resizer.vertical
	width: 10px
	background-color: red
	z-index: 1

.custom-request-modal.modal-dialog
	margin: 20px auto 0px auto
	.modal-content
		.help-block
			margin-top: -5px
		.request-timestamp
			margin-bottom: 5px
			max-width: 300px
			.rdtPicker
				position: fixed
	.video-request-labels
		display: block
	.video-request-label
		display: none
	@media (max-width: 991px)
		.video-request-labels
			display: none
		.video-request-label
			display: block
			font-weight: 700
			font-size: 16px

.event-alert-search-table
	.react-bs-table
		overflow: auto
		.rosco-table-header,
		.rosco-table-body
			width: auto
			min-width: 600px
	.react-bs-table-pagination
		.shows-total
			color: #666666
			font-size: 12px
			padding-top: 5px

#popover-basic
	width: 260px
	display: block
	position: absolute
	z-index: 10000

.wifipoints-tree
	padding-bottom: 15px
	height: calc(100% - 116px)
	ol
		padding: 0 2px 0 24px
		height: 100%
		overflow-y: auto
		overflow-x: hidden
	li
		cursor: pointer
		font-weight: bold
		position: relative
		padding-right: 14px
	.delete-wifipointer-icon
		position: absolute
		top: 0
		right: 0
		i
			float: right
			margin-left: 5px
			color: #005c9c
			font-size: 16px
			vertical-align: middle
		i:hover
			transform: scale(1.5)

body:not(.mobile),
body.mobile.landscape
	.live-video-player
		.container
			video
				transform: scaleX(1.45)
				-ms-transform: scaleX(1.46)
				-o-transform: scaleX(1.45)
				-webkit-transform: scaleX(1.45)
				-moz-transform: scaleX(1.45)
body.mobile
	.live-video-player
		&.new-window-video
			@media (max-width: 767px)
				.live-video-container
					height: 85% !important
				.continue-watching
					height: 15%
