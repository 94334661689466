.advanced-player-container
    .modal-body
        padding: 0
        border-radius: 5px
    &.alert-search-container .modal-body
        @media (min-width: 992px)
            overflow: hidden
    .loaderModal
        top: auto
        bottom: 0
        height: 100%
        
#advanced-player-mp4videos
    width: 200px
    z-index: 100005
    .close-btn
        position: absolute
        border: none
        color: var(--theme-colors-tertiary)
        font-weight: bold
        font-size: 24px
        background-color: transparent
        top: -7px
        right: 3px
        &:hover, &:active, &:focus
            color: #337ab7
        .sr-only
            border: 0
            clip: rect(0, 0, 0, 0)
            height: 1px
            margin: -1px
            overflow: hidden
            padding: 0
            position: absolute
            width: 1px
    .popover-content
        padding: 9px 14px
        a
            display: block
            color: #337ab7
            text-decoration: none
            &:focus, &:hover
                color: #23527c
                text-decoration: underline
    .popover-title
        padding: 8px 14px
        margin: 0
        font-size: 14px
        background-color: #f7f7f7
        border-bottom: 1px solid #ebebeb
        border-radius: 5px 5px 0 0
#advanced-web-player
    .metadata-column
        vertical-align: top
        position: relative
        @media (min-width: 992px)
            border-left: 2px solid #4c4c4c
    &.permalink
        .video-column, .metadata-column
            padding: 0px
        .video-column
            cursor: default
        .channel1-wrapper .video-slider
            .slider-container
                top: 16px
                width: 86%
                margin-left: 0px
        .metadata-column
            border: 2px solid #4c4c4c
            @media (max-width: 991px)
                border-right: 2px solid #4c4c4c
            .video-player-button
                border-color: var(--theme-colors-primary)
                color: var(--theme-colors-primary)
                padding-top: 2px
                margin-left: 0px
                &:nth-child(2)
                    padding-top: 6px
                    margin-top: 5px
                svg
                    position: relative
                    top: 5px
                    right: 3px
            #player-metadata
                margin-right: 5px
                .category-title
                    color: var(--theme-colors-secondary)
        @media (min-width: 992px)      
            .video-column
                width: 60%
            .metadata-column
                width: 40%
        .tooltipDoubleClick
            left: calc(50% - 106px)
    .tooltipDoubleClick
        position: absolute
        top: 0
        width: fit-content
        text-align: center
        left: calc(19% - 134px)
        z-index: 1000
        border-radius: 0px 0px 5px 5px
        background-color: var(--theme-colors-primary)
        color: #fff
        font-size: 14px
        padding: 5px 10px
        display: none
        cursor: default
    .video-column
        height: 100%
        background-color: #000
        position: relative
        display: inline-block
        &:hover 
            .change-view, .draggable-wrapper
                display: block
        .change-view
            color: #fff
            cursor: pointer
            padding-right: 10px
            display: none
            &.rotate
                transform: rotate(270deg)
                padding: 0px 5px 10px 5px
            &.active
                color: #0071b6
        .empty-channel
            background-image: url('../../../../../../public/images/roscovision-icon.png')
            background-size: 40%
            background-repeat: no-repeat
            background-position: center
            opacity: 0.75
        .draggable-wrapper
            position: absolute
            top: 0px
            width: 100%
            color: #fff
            text-align: center
            display: none
            .draggable-element
                cursor: move
                border-radius: 0px 0px 5px 5px
                background-color: rgba(0,0,0,0.5)
        .video-grid
            display: grid
            grid-template-rows: calc(50% - 25px) calc(50% - 25px) 50px
            height: 100%
            &.four-channels
                grid-template-columns: 1fr 1fr
                .channel
                    &:first-child
                        border: solid #fff
                        border-width: 0px 1px 1px 0px
                    &:nth-child(2)
                        border-bottom: 1px solid #fff
                    &:nth-child(3)
                        border-right: 1px solid #fff
                .video-slider
                    border-top: 1px solid #fff
                    grid-column-start: 1
                    grid-column-end: 3
            .channel
                h1
                    width: 100%
                    height: 100%
                    text-align: center
                    margin: 0px
                    video
                        width: 100%
                        height: 100%
                    #sound
                        color: #fff
            .video-slider
                width: 100%
                display: flex
                align-items: center
                @media (max-width: 991px)
                    overflow: hidden
                #play
                    z-index: 10
                    background-color: transparent
                    border: none
                    color: #fff
                    font-size: 30px
                    outline: none !important
                    padding-bottom: 5px
                .slider-container
                    flex-basis: 100%
                    margin: 0px 15px 0px 5px
                    #video-slider.slider.slider-horizontal
                        width: 100%
                        .slider-track
                            background-color: #646364
                            background-image: none
                            height: 8px
                            border: none
                            .slider-selection
                                background-image: none
                                background-color: var(--theme-colors-primary)
                        .slider-handle
                            display: none
                #sound
                    color: #fff
                    margin-right: 5px
                    cursor: pointer
    &.grid 
        @media (min-width: 992px)
            .video-column
                width: 60%
            .metadata-column
                width: 40%
    .small-close-button
        position: absolute
        background-color: #fff
        z-index: 10
        opacity: 0.8
        padding: 2px 3px
        border-bottom-right-radius: 5px
        &:focus
            outline: none
        @media (min-width: 992px)
            display: none
    #player-video
        .channel
            position: relative
        .channel1
            width: 100%
            #channel1
                width: 100%
        h1
            margin: 0px
            line-height: 0px
            #sound
                position: absolute
                right: 10px
                bottom: 18px
                cursor: pointer
                @media (max-width: 991px)
                    display: none
        video, img
            width: 100%
        .wrapper
            display: -ms-grid
            -ms-grid-rows: 100%
            display: grid
            grid-template-columns: 33.333% 33.333% 33.333%
            grid-gap: 0
            align-content: space-evenly
            position: relative
            &.center-align .tooltipDoubleClick
                left: calc(50% - 134px)
            &:hover .tooltipDoubleClick
                display: block
            &.wrapper-block
                display: block
            &.single-channel
                display: block
            .channel
                margin: 0
                padding: 0
                height: 100%
                width: 100%
                display: table
                h1
                    display: table-cell
                    vertical-align: middle
                    line-height: 0
                    position: relative
                    #sound
                        color: #fff
                        position: absolute
                        bottom: 0
                        right: 0
                        width: 36px
            .empty-channel:nth-child(2)
                    border-right: 1px solid #333
            &.ios-device
                .channel:not(.active)
                    display: none
            .channel1
                -ms-grid-row: 1
                -ms-grid-column: 1
            .channel2
                -ms-grid-row: 1
                -ms-grid-column: 2
            .channel3
                -ms-grid-row: 2
                -ms-grid-column: 1
            .channel4
                -ms-grid-row: 2
                -ms-grid-column: 2
            &.small-width
                .channel1
                    -ms-grid-row: 1
                    -ms-grid-column: 1
                .channel2
                    -ms-grid-row: 2
                    -ms-grid-column: 1
                .channel3
                    -ms-grid-row: 3
                    -ms-grid-column: 1
                .channel4
                    -ms-grid-row: 4
                    -ms-grid-column: 1
        .channel-select
            background-color: #fff
            height: 42px
            padding-top: 10px
            text-align: center
            ul
                margin: 0px
    .channel1-wrapper
        position: relative
        .video-slider
            position: absolute
            bottom: 0px
            background-image: linear-gradient(transparent, #000)
            width: 100%
            display: flex
            align-items: center
            #play
                z-index: 10
                background-color: transparent
                border: none
                color: #fff
                min-width: 30px
                min-height: 30px
                outline: none !important
                padding-bottom: 5px
            .slider-container
                flex-basis: 100%
                margin: 0px 15px 0px 5px
                #video-slider.slider.slider-horizontal
                    width: 100%
                    .slider-track
                        background-color: #646364
                        background-image: none
                        height: 8px
                        border: none
                        .slider-selection
                            background-image: none
                            background-color: var(--theme-colors-primary)
                    .slider-handle
                        display: none
            #sound, .sound
                color: #fff
                margin-right: 5px
                cursor: pointer
    .control-buttons
        text-align: center
        position: relative
        .timer
            position: absolute
            left: 20px
            top: calc(50% - 8px)
            font-size: 16px
            line-height: 16px
            @media (max-width: 660px)
                position: static
        .download-buttons
            position: absolute
            right: 20px
            top: calc(50% - 26px)
            font-size: 16px
        .timer-static
            position: static
        .speed-slider
            position: relative
            display: inline-block
            @media (max-width: 660px)
                display: block
        .speed-slider-block
            display: block
            text-align: left
        .speed-value
            font-size: 11px
        .playback-speed
            position: absolute
            z-index: 1
            .slider.slider-horizontal
                width: 160px
                height: 12px
                margin: 0 6px
                .slider-handle
                    top: 0px
                    width: 12px
                    height: 12px
                    margin-left: -6px
            .slider.slider-vertical
                height: 140px
                width: 17px
                margin: 6px 0
                .slider-handle
                    top: 0px
                    width: 12px
                    height: 12px
                    margin-top: -6px
                    margin-left: 3px
            .slider-track
                //height: 13px
                border: 1px solid #ccc
                .slider-rangeHighlight.slider-selection
                    background: none
                    background-color: #33abb7
        .wrapper-buttons
            position: relative
            padding: 0 5px
            display: inline-block
            padding-left: 44px
            //border: 1px solid #ccc
            button
                position: relative
                padding: 0
                margin: 0 2px
                font-size: 30px
                border: none
                line-height: 26px
                .mirror-scale
                    transform: scale(-1,1)
            #play
                background-color: transparent
                font-size: 18px
                padding: 4px
                span
                    color: #fff
            #pause
                margin: 0
            .volume-value
                position: absolute
                bottom: 29px
                right: 3px
                width: 28px
                background-color: #fff
                border: 1px solid #ccc
    .player-header
        .metadata-header
            font-size: 18px
            padding: 5px
            display: flex
            flex-wrap: wrap
            .close
                align-self: flex-start
            .vehicle-name
                font-weight: 500
                vertical-align: top
                &.clickable:hover
                    text-decoration: underline
                    cursor: pointer
            .event-id
                margin-right: 2px
                margin-left: auto
    #player-metadata
        padding: 0px 5px 10px
        border: 1px solid #aaa
        border-radius: 8px
        margin: auto 5px
        cursor: default
        position: relative
        .flagged-button
            position: absolute
            left: 5px
            cursor: pointer
            &.on
                color: gold

        .player-data
            display: flex
            justify-content: space-between
            align-items: center
            h4
                text-align: center
                margin: auto
                padding: 5px 0 10px 0
            button
                margin-bottom: 8px
            #download-menu-dropdown
                position: absolute
                right: 0px
                top: 4px
                margin-left: 5px
                h4
                    padding: 10px 0 5px 0
                    margin: 0
                .event-actions
                    height: 45px
                    width: 45px
                    background-size: 45px 45px
                    background-color: var(--theme-colors-tertiary) 
                    float: none
                    display: inline-block
                    margin-left: 5px
                    background-repeat: no-repeat
                    border-radius: 4px
                &.downloadButton
                    background-size: 40px 45px
                    background-image: url('../../../../../../public/images/ico/event_download.png')
                    border-color: var(--theme-colors-tertiary) 
                &.shareButton
                    background-image: url('../../../../../../public/images/ico/event_share.png')
                &.flaggedButton
                    background-image: url('../../../../../../public/images/ico/event_flagged_off.png')
                .download-menu
                    top: -35px !important
                    min-width: 195px
                    #video-player-button, .video-player-button
                        border: none
                        display: block
                        width: 100%
                        text-align: left
                        padding: 5px
                        margin: 0px
                        font-size: 14px
                        svg
                            margin: 0 3px 2px 0
                        &:hover, &:focus
                            background-color: #e6e6e6
                        &:active, &:active:focus
                            background-color: #ccc
            .category-title
                color: var(--theme-colors-secondary)
                text-align: center
                font-size: 22px
                font-weight: 200
                margin-bottom: 4px
        .mobileye-list
            li
                font-size: 16px
                .header-item
                    margin-right: 5px
        ul
            list-style-type: none
            padding-left: 0
            margin-bottom: 0
            &.actions-menu
                max-height: 400px
                overflow-y: auto
                max-width: 260px
                .menu-action
                    white-space: nowrap
                    text-overflow: ellipsis
                    overflow: hidden
            li
                line-height: 25px
                .metadata-logo
                    display: inline-block
                .header-value
                    position: relative
                    bottom: -3px
                    padding-left: 8px
                    font-size: 16px
                    &.header-button
                        background: transparent
                        border: none
                        border-radius: 5px
                        padding: 4px
                        margin-left: 2px
                        font-size: 16px
                        box-shadow: none
                        line-height: 1
                        &:hover
                            background-color: #f0f0f0
                .header-value-driver
                    color: black
                    &:hover, &:active, &:focus
                        background-color: #f0f0f0
                .dropdown
                    line-height: inherit
                &.note-row
                    background-color: #f0f0f0
                    cursor: text
                    border-radius: 5px
                    margin: 4px -2px
                    padding: 2px 2px 1px
                    &:hover
                        background-color: #fff
                        border: 1px solid #888
                        padding: 1px 1px 0px
            .eventNameRow
                display: flex
                .eventLabelBlock
                    display: flex
                    align-items: center
                    margin: 0 0 -9px 10px
                    gap: 10px
                    .eventLabel
                        padding: 0px 5px
                        font-weight: 500
                        background-color: #f0f0f0
                @media (max-width: 1500px)
                    flex-direction: column
                    .eventLabelBlock
                        &.text
                            margin: 0 0 0 25px
    .action-buttons
        margin-top: 5px
        text-align: center
        .imageButton
            height: 45px
            width: 45px
            background-size: 45px 45px
            background-color: var(--theme-colors-tertiary)
            float: none
            display: inline-block
            margin-left: 5px
            background-repeat: no-repeat
            border-radius: 4px
            &.downloadButton
                background-size: 40px 45px
                background-image: url('../../../../../../public/images/ico/event_download.png')
                border-color: var(--theme-colors-tertiary)
            &.shareButton
                background-image: url('../../../../../../public/images/ico/event_share.png')
            &.flaggedButton
                background-image: url('../../../../../../public/images/ico/event_flagged_off.png')
        .download-buttons
            margin-top: 5px
            text-align: center
            .dropdown
                vertical-align: top
                .dropdown-menu 
                    text-align: center
                    button
                        border: none
                #download-menu-dropdown
                    background-color: var(--theme-colors-tertiary)
                    color: #fff
                    margin-left: 5px
        .green-text
            color: #17e92c
        .red-text
            color: red
    #files-list
        list-style-type: none
    .acceleration-values
        position: absolute
        bottom: -5px
        width: 100%
        text-align: center
        .x-value
            color: red
        .y-value
            color: #04eb0d
        .z-value
            color: #11cfd6 
    @media (min-width: 992px)      
        .video-column
            width: 75%
        .metadata-column
            width: 25%
            display: inline-block
    &.permalink
        #player-metadata ul li
            line-height: unset
            .header-item
                width: 105px
                font-size: 14px
        @media (max-width: 991px)
            margin: -15px
    #player-map
        width: 100% 
        border-top: 2px solid #4c4c4c
        margin-top: 20px
        @media (min-width: 992px)
            position: absolute 
            bottom: 0 
            margin-top: 0px
        @media (max-width: 991px)
            height: 350px !important
    .right-column-info
        position: relative
        .download-buttons
            display: inline-block
            .downloadButton
                background-color: transparent
                &:active, &:focus
                    box-shadow: none
                &:hover
                    background-color: #e6e6e6
            .video-player-button
                background-color: #fff
                white-space: nowrap
                padding: .375rem .75rem
                svg
                    top: 0