html
  min-height: 100%

// USER AND NOTIF DROPDOWNS
.header-nav
  position: fixed
  top: 15px
  right: 20px
  z-index: 10
  .notifications-dropdown
    padding-right: 5px
  .dropdown, .dropup
    display: inline-block
  @media ( max-width: 767px )
    top: 5px
    right: 10px
    z-index: 10000

  //DROPDOWN TOGGLES
  .dropdown
    .user-toggle, .notifications-toggle
      padding: 0px
      line-height: 0
      background-color: transparent
      box-shadow: none 
      color: #333
      border: none
      transition: color 0.3s
      box-shadow: none
      @media (min-width: 768px)
        &:hover
          color: var(--theme-colors-tertiary)
    .user-toggle svg
      height: 2.25em
      width: 2.25em
    .notifications-toggle
      height: 1.75em
      width: 1.75em
    &.show
      .user-toggle, .notifications-toggle
        color: var(--theme-colors-tertiary)
        background-color: transparent
    
  // USER DROPDOWN
  .user-icon-dropdown
    .user-account-menu
      width: 300px
      padding: 0px
      // PARTNER / COMPANY SELECT
      .company-select-menu
        min-width: 200px
        padding: 5px
        border-bottom: 2px solid var(--theme-colors-primary)
        .select-company-container
          .company-select-wrapper
            display: flex
            align-items: center
            & + .company-select-wrapper
              margin-top: 5px
            .company-select-label
              display: inline-block
              font-size: 1rem
              margin-right: 5px
            .partner-select, .company-select
              flex-basis: 100%
            .partner-select
              display: inline-block
              position: relative
              .search-autocomplete-header
                width: 100%
                i
                  position: absolute
                  bottom: 10px
                  right: 8px
                  cursor: pointer
            .company-select
              display: inline-block
              position: relative
              .search-autocomplete-header
                width: 100%
                i
                  position: absolute
                  bottom: 10px
                  right: 8px
          input
            padding: 6px
            font-size: 0.875rem
          .search-autocomplete-header .items-list
            .item
              cursor: pointer
              padding: 2px 4px
              font-size: 0.875rem
              &.has-error
                text-align: center
                color: #f00
                cursor: default
            .item-highlighted
              background-color: var(--theme-colors-tertiary)
              color: #ffffff
              padding: 2px 4px
      //LINKS IN USER DROPDOWN
      .user-menu-item
        color: #000
        text-align: center
        font-weight: 300
        font-size: 1rem
        &:hover
          a, .button-link
            color: var(--theme-colors-tertiary)
            text-decoration: none
        a, .button-link
          color: #333
          display: block
          padding: 3px
        .button-link
          font-size: 1rem
          font-weight: 100
          width: 100%
        &.user-menu-title
          color: var(--theme-colors-primary)
          padding: 5px
          font-size: 1.25rem
          border-bottom: 2px var(--theme-colors-primary) solid
          cursor: default
          .user-initials
            display: flex
            justify-content: center
            align-items: center
            background-color: var(--theme-colors-primary)
            color: #fff
            font-size: 2.5rem
            font-weight: 400
            border-radius: 50%
            height: 75px
            width: 75px
            margin: 7px auto
          .user-menu-subtitle
            font-size: 0.875rem
            color: #333
        &.logout
          background: var(--theme-colors-primary)
          transition: background-color, 0.3s
          &:hover
            background: var(--theme-colors-tertiary)
          .button-link
            color: #fff
      .languageDropdown
        display: flex
        justify-content: center
        .languageDropdownMenu
          min-width: 140px !important
          width: 140px !important
          inset: -41px auto auto -137px !important
          .dropdown-item
            text-align: center
            text-decoration: none
            &:hover
              background: var(--theme-colors-tertiary)
              color: #fff
        .dropdown-toggle
          display: flex !important
          flex-direction: row
          justify-content: center
          align-items: center
          background-color: #fff
          color: #333
          display: block
          padding: 3px
          &:hover
            color: var(--theme-colors-primary)

  // NOTIFICATIONS DROPDOWN
  .notifications-dropdown
    .notifications-menu
        min-width: 420px
        overflow: auto
        max-height: 250px
        padding: 0
        .clear-notifications
            float: right
            font-size: inherit
        @media (max-width: 767px)
            min-width: 300px
        li
          list-style-type: none
          background-color: #edf2fa
          border-bottom: 1px solid #dddfe2
          position: relative
          display: block
          padding: 3px 20px
          &.group
              background-color: #f5f6f7
              border-bottom: 1px solid #dddfe2
              color: #90949c
              // font-family: Helvetica, Arial, sans-serif
              font-family: var(--theme-font)
              font-size: 0.6875rem
              font-weight: 500
              padding: 5px 12px
          .close
              position: absolute
              right: 3px
              top: 0
          a
            // color: #000
            font-size: 0.875rem
            // font-weight: bold
            &:hover, &:active, &:focus, &:visited
              text-decoration: none
            span
              color: #CCCCCC
              margin-left: 7px
              border-top: 7px dashed
              border-right: 6px solid transparent
              border-left: 6px solid transparent
          .notification-item
            display: flex
            flex-direction: column
            justify-content: center
            margin-top: -1px
            min-height: 50px
            word-wrap: break-word
            font-size: 0.75rem
            position: relative
            p
                margin: 0 20px 1px 0
            .btn-completed
              margin-left: 5px
              padding: 0 2px
            .view-link
              color: #7CA6EB
            .close-button
              top: 20px
    .alert-bubble
        font-size: 0.625rem
        top: -7px
        right: -8px
        @media (max-width: 767px)
          top: -4px
          right: -9px 
    .empty-notifications
      padding: 1px 3px
      text-align: center
      font-size: 1rem